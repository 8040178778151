/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import history from "../../routes/History";
import "./model.scss";
import PaymentModel from "../Profile/payment/paymentModel";
import SubscriptionCard from "./subscriptionCard";
import ArrowLeft from "../../assets/images/componies/ArrowLeft.svg";
import * as ProfileActions from "../../store/profile/profileActions";
import UnnlockLayout from "../layout";

const SubscriptionContainer = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.profile.loading);
  const subscriptions = useSelector((state) => state.profile.subscriptions);
  const currentSub = useSelector((state) => state.profile.currentSubscription);
  const customerId = useSelector((state) => state.profile.customerId);
  const basicInfo = useSelector((state) => state.profile.basicInfo);

  useEffect(() => {
    if (
      customerId === null &&
      basicInfo.email !== "" &&
      typeof customerId !== "string"
    ) {
      const formData = {
        name: `${basicInfo.firstName} ${basicInfo.lastName}`,
        email: basicInfo.email,
      };
      dispatch(
        ProfileActions.createCustomerRequest({
          formData,
        })
      );
    }
  }, [basicInfo]);

  return (
    <UnnlockLayout>
      <div className="subscription_model">
        <Spin size="large" spinning={isLoading}>
          <div>
            <div className="plansContainer">
              <div
                style={{
                  // background: "#4389fa",
                  // color: "white",
                  padding: "15px",
                  // borderRadius: "8px",
                  position: "absolute",
                  top: "2%",
                  left: "2%",
                  cursor: "pointer",
                  // fontWeight: "600",
                }}
                onClick={() => history.goBack()}
              >
                <img
                  src={ArrowLeft}
                  alt="arrow"
                  style={
                    {
                      // marginBottom: "4px",
                      // width: "20px",
                      // marginRight: "8px",
                    }
                  }
                />
              </div>
              <div className="subheader">
                <h1 className="subsTitle">Choose Your Plan</h1>
                <p className="subsDesc">
                  You are currently using the Free version. Upgrade to unlock
                  all features
                </p>
              </div>
              <div className="cardsDiv">
                {subscriptions.map((item, i) => {
                  // if (!(item.tier_id === currentSub.id)) {
                  return (
                    <SubscriptionCard
                      currentSub={item.tier_id === currentSub.id}
                      subsData={item}
                      themetype={i % 2 === 0 ? "white" : "blue"}
                    />
                  );
                  // }
                })}
              </div>
            </div>
            <PaymentModel />
          </div>
        </Spin>
      </div>
    </UnnlockLayout>
  );
};

SubscriptionContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default SubscriptionContainer;
