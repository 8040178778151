import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Skeleton, Tooltip, Row, Col, Select, Radio } from "antd";
import dropdownArrow from "../../assets/images/personalDashboard/arrowDown.svg";
import RatingBar from "../OfferEvaluatorResult/RatingBar";
import CommonInput from "../ICDProvider/commonInput";
import IntresLevel from "./intrestLevel";
// import AppliedOn from "./appliedOn";
import {
  deleteJobTrackRequest,
  getJobTrackeresRequest,
  jobMatchFetchFailure,
  jobMatchFetchRequest,
  // jobMatchResultsRequest,
  jobMatchResultsrFailure,
  setTrackerID,
  setTrackerIDToUpdate,
  updateJobMatchRequest,
} from "../../store/jobMatch/jobMatchActions";
import JobMatchResultsContainer from "../JobMatchResults/JobMatchResultsContainer";
import ArrowLeft from "../../assets/images/componies/ArrowLeft.svg";
import {
  getResumeVaultsRequest,
  setCurrentResumeId,
} from "../../store/resumeOptimizer/resumeOptimizerActions";

// const optionsEducation = [
//   { value: undefined, title: "Select Education" },
//   { value: "Masters", title: "Masters" },
//   { value: "PHD", title: "PHD" },
//   { value: "Bachelors", title: "Bachelors" },
// ];

// const optionsExperience = [
//   { value: undefined, title: "Select Experience" },
//   { value: 1, title: "1 Years" },
//   { value: 5, title: "5 Years" },
//   { value: 10, title: "10 Years" },
//   { value: 15, title: "15 Years" },
//   { value: "20+", title: "20+ Years" },
// ];

const JobTracker = ({ handleViewAll, viewJTAll }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [showJMResults, setshowJMResults] = useState(false);
  const [clickCheckJob, setclickCheckJob] = useState(false);
  // const [showJMForm, setshowJMForm] = useState(false);
  const [currentJId, setcurrentJId] = useState("");
  const [initialFormData, setinitialFormData] = useState({
    comp_name: "",
    comp_website: "",
    job_title: "",
    education: "",
    experience: "",
    relocating: true,
    job_desc: "",
    resume_id: "",
  });
  const [statusValues, setStatusValues] = useState([
    "Saved",
    "Applied",
    "Interviewing",
    "Offer",
  ]);
  const {
    jobTrackeres: jobTracks,
    trackerID,
    loader,
    jobMatchResultData,
    jobMatchFetchData,
    showUpdateSkillsModal,
  } = useSelector((state) => state.jobmatch);
  const loginState = useSelector((state) => state.login);
  const { resumeVaults } = useSelector((state) => state.resumeOptimizer);
  // const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);

  const onChange = (e) => {
    const arr = `${e}`.split(",");
    if (arr[0] === "") setSelected([]);
    else setSelected(`${e}`.split(","));
  };

  const handleDelete = () => {
    const postData = {
      tracker_id: selected,
    };
    dispatch(deleteJobTrackRequest(postData));
    setTimeout(() => {
      setSelected([]);
    }, 2000);
  };

  useEffect(() => {
    if (jobMatchFetchData) {
      setinitialFormData({
        comp_name: jobMatchFetchData.company_name,
        comp_website: jobMatchFetchData.company_url,
        industry: jobMatchFetchData.industry,
        job_title: jobMatchFetchData.job_title,
        education: jobMatchFetchData.education,
        experience: jobMatchFetchData.required_experience,
        job_desc: jobMatchFetchData.text,
        resume_file_name:
          jobTracks?.length &&
          jobTracks?.filter((item) => item.job_match_id === trackerID)[0]
            ?.file_name,
      });
    }
  }, [jobMatchFetchData]);

  useEffect(() => {
    if (loginState.userData && loginState.userData.primary_resume) {
      dispatch(getJobTrackeresRequest({ limit: "all", fromTracker: true }));
    }
  }, [loginState.userData]);

  useEffect(() => {
    if (Object.keys(jobMatchResultData).length !== 0 && clickCheckJob) {
      setshowJMResults(true);
    } else {
      setshowJMResults(false);
    }
  }, [jobMatchResultData]);

  useEffect(() => {
    if (jobTracks?.length) getTableRowClick(trackerID);
  }, [trackerID, jobTracks]);

  useEffect(() => {
    localStorage.setItem("unnlockJMUnamatchedSkills", "");
    dispatch(getResumeVaultsRequest());
    return () => {
      setclickCheckJob(false);
      dispatch(jobMatchResultsrFailure());
      dispatch(jobMatchFetchFailure());
      dispatch(setTrackerID(""));
      setinitialFormData({
        comp_name: "",
        comp_website: "",
        job_title: "",
        education: "",
        experience: "",
        relocating: true,
        job_desc: "",
        resume_id: "",
      });
    };
  }, []);

  const JTArray = viewJTAll ? jobTracks : jobTracks.slice(0, 4);

  const getTableRowClick = (keyRow) => {
    const postdata = {
      matchId: keyRow,
      donotSave: true,
      resume_id:
        (resumeVaults?.length &&
          resumeVaults?.filter(
            (item) => item.cloned_from_tracker && item.job_match_id === keyRow
          )[0]?.resume_id) ||
        jobMatchFetchData?.resume_id,
    };

    if (keyRow) {
      if (currentJId === keyRow) {
        setclickCheckJob(false);
      } else {
        setclickCheckJob(true);
        setcurrentJId(keyRow);
        dispatch(jobMatchFetchRequest(postdata));
      }
    } else {
      setclickCheckJob(false);
      setcurrentJId("");
    }
  };
  const handleRowClick = (id) => {
    if (currentJId === id) {
      dispatch(setTrackerID(""));
    } else {
      dispatch(setTrackerID(id));
    }
  };

  const trackerLoader = showUpdateSkillsModal ? false : loader;

  const isShowTableContainer = () => {
    if (!showJMResults && clickCheckJob && !loader) {
      return (
        <div className="contentContainer">
          <p style={{ margin: "0px" }}>
            No resume found with provided resume_id for this user
          </p>
        </div>
      );
    } else {
      return (
        <div className="tableContainer">
          <Skeleton loading={trackerLoader} active>
            <Checkbox.Group onChange={onChange}>
              {jobTracks.length ? (
                <table>
                  <tr>
                    <th>Select</th>
                    <th>Company</th>
                    <th style={{ width: "20%" }}>Job Title</th>
                    <th>Interest Level</th>
                    <th style={{ width: "16%" }}>Resume Used</th>
                    <th>Match Score</th>
                    <th>Status</th>
                    {/* <th style={{ width: "16%" }}>Website</th> */}
                  </tr>

                  {JTArray.map((item, i) => (
                    <tr>
                      <td>
                        <Checkbox value={item.job_tracker_id} />
                      </td>
                      <td
                        colspan="2"
                        className="companyName"
                        onClick={() => {
                          dispatch(setTrackerIDToUpdate(item.job_tracker_id));
                          handleRowClick(item.job_match_id);
                          dispatch(setCurrentResumeId(item.resume_id));
                        }}
                      >
                        <Tooltip
                          placement="topLeft"
                          title="Click to view details"
                        >
                          <div
                            className="companyNameandjobtitle"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              // textAlign: "center",
                            }}
                          >
                            <div style={{ width: "50%" }}>{item.company}</div>
                            <div style={{ width: "50%" }}>{item.job_title}</div>
                            <img
                              src={dropdownArrow}
                              className="dropdownArrow"
                            />
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <IntresLevel index={i} value={item.interest_level} />
                      </td>
                      <td>{item.file_name}</td>
                      <td>
                        <RatingBar ratings={item.match_score} />
                      </td>
                      <td>
                        {/* <AppliedOn index={i} value={item.applied_on} /> */}
                        <Select
                          defaultValue="Select Status"
                          onChange={(e) => {
                            dispatch(
                              updateJobMatchRequest({
                                job_tracker_id: item.job_tracker_id,
                                jt_status: e,
                              })
                            );
                          }}
                          value={item?.jt_status || undefined}
                        >
                          <Option key="0">Select Status</Option>
                          {statusValues.map((i) => (
                            <Option key={i}>{i}</Option>
                          ))}
                        </Select>
                      </td>
                      {/* <td>{item.company_url}</td> */}
                    </tr>
                  ))}
                </table>
              ) : null}
            </Checkbox.Group>
          </Skeleton>
        </div>
      );
    }
  };

  const handleBackClick = () => {
    setclickCheckJob(false);
    dispatch(getJobTrackeresRequest({ limit: "all" }));
  };

  console.log(showJMResults, jobMatchResultData, "trackerID");

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
          display: "flex",
          backgroundColor: "white",
          borderRadius: "16px",
          marginBottom: viewJTAll ? "14px" : "4px",
          padding: "12px",
        }}
      >
        <div onClick={handleBackClick} style={{ cursor: "pointer" }}>
          {clickCheckJob && (
            <img
              src={ArrowLeft}
              alt="arrow"
              style={{
                marginBottom: "4px",
                width: "20px",
                marginRight: "8px",
              }}
            />
          )}
          <span className="title">
            {clickCheckJob ? "Job Tracker | Details" : "Job Tracker"}
          </span>
        </div>
        <div style={{ display: "flex" }}>
          {selected.length ? (
            <button
              style={{ width: "auto", background: "white", marginRight: "8px" }}
              className="add-date-button"
              onClick={handleDelete}
            >
              Delete
            </button>
          ) : null}
          {jobTracks.length && !loader && !viewJTAll ? (
            <p
              style={{
                margin: "0",
                backgroundColor: "#eef3fe",
                padding: "4px 16px",
                borderRadius: "8px",
                cursor: "pointer",
                fontWeight: "500",
              }}
              onClick={handleViewAll}
            >
              View All
            </p>
          ) : null}
        </div>
      </div>
      {clickCheckJob ? (
        <div
          className="clickCheckJob"
          // style={{ display: "flex" }}
        >
          <div
            className="tableContainer"
            style={{ marginRight: "5px", maxHeight: "537px", overflow: "auto" }}
          >
            <table>
              <tr>
                <th>Company</th>
                <th>Job Title</th>
              </tr>

              {JTArray.map((item, i) => (
                <tr
                  style={{
                    background:
                      currentJId === item.job_match_id ? "gainsboro" : "",
                  }}
                >
                  <td
                    colspan="2"
                    className="companyName"
                    onClick={() => handleRowClick(item.job_match_id)}
                  >
                    <Tooltip placement="topLeft" title="Click to view details">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            textAlign: "left",
                          }}
                        >
                          {item.company}
                        </div>
                        <div style={{ width: "50%" }}>{item.job_title}</div>
                        {currentJId === item.job_match_id ? (
                          <img
                            src={dropdownArrow}
                            className="dropdownArrowSelected"
                          />
                        ) : null}
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div
            // style={{ width: "80%" }}
            className="contentContainerwrap"
          >
            <Skeleton loading={trackerLoader} active>
              <div className="contentContainer">
                <Row justify="space-between">
                  <Col md={8} className="selectResume">
                    <CommonInput
                      style={{
                        width: "100%",
                      }}
                      value={initialFormData.resume_file_name}
                    />
                  </Col>

                  <Col md={8} style={{ paddingLeft: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ margin: "0px 0 0px" }}>
                        <CommonInput
                          style={
                            {
                              //paddingBottom: "29px",
                            }
                          }
                          value={initialFormData.comp_website}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div style={{ margin: "29px 0 0px" }}>
                      <CommonInput
                        style={{
                          paddingBottom: "29px",
                        }}
                        value={initialFormData.comp_name}
                      />
                    </div>
                  </Col>

                  <Col md={12} style={{ paddingLeft: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ margin: "29px 0 0px" }}>
                        <CommonInput
                          style={{
                            paddingBottom: "29px",
                          }}
                          value={initialFormData.job_title}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={8}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <CommonInput
                          style={{
                            paddingBottom: "29px",
                          }}
                          value={initialFormData.education}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={8} style={{ paddingLeft: "15px" }}>
                    <CommonInput
                      style={{
                        paddingBottom: "29px",
                      }}
                      value={initialFormData.industry}
                    />
                  </Col>

                  <Col md={8} style={{ paddingLeft: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <CommonInput
                          style={{
                            paddingBottom: "29px",
                          }}
                          value={initialFormData.experience}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={24}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ margin: "0px 0 0px" }}>
                        <CommonInput
                          name="job_desc"
                          placeholder="Copy and paste the job description here..."
                          value={initialFormData.job_desc}
                          type="textarea"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Skeleton>
          </div>
        </div>
      ) : null}
      {showJMResults && clickCheckJob ? (
        <>
          <Skeleton loading={trackerLoader} active>
            <JobMatchResultsContainer handleEdit={initialFormData} />
          </Skeleton>
        </>
      ) : (
        isShowTableContainer()
      )}
    </div>
  );
};

export default JobTracker;
