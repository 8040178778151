import React from 'react'
import './landingfooter.scss'
import unlockwhitewithoubg from "../../assets/images/landing/unlockwhitewithoubg.svg";
import history from "../../routes/History";

const LandingFooter = () => {
  return (
    <section className='landng-footer__Section'>
      <div className='landing-footer__flexcontainer'>
        <div className='footerlogo-address__container'>
          <img
            src={unlockwhitewithoubg}
            alt="unnlock"
            style={{ width: "150px" }}
          />
          <p className='footer-lading__date date_section'>
            ©  {new Date().getFullYear()} unnlock. All rights reserved.
          </p>
        </div>
        <div className='footer-stiemap__Section'>
          <div className="title">feedback@unnlock.com</div>
        </div>
        <div className="footer-login__section">
          <div>
            <span
              onClick={() => {
                history.push("/newRegistration");
              }}
            >
              LOGIN
            </span>
          </div>
          <div style={{ margin: "5px 0px" }}>Or</div>
          <div>
            <span
              onClick={() => {
                history.push("/newRegistration");
              }}
            >
              SIGN UP
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingFooter