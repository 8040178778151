/*eslint-disable*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentResumePage } from "../../../store/resumeOptimizer/resumeOptimizerActions";
import EditButton from "./editButton";

const checkForEmpty = (arr) => {
  if (Array.isArray(arr) && arr.length) {
    if (arr.length === 1) {
      if (Object.values(arr[0]).every((val) => val === "")) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  } else {
    return false;
  }
};

const Template1 = ({ isEdit }) => {
  const dispatch = useDispatch();
  const {
    contactInfo,
    summary,
    educationInfo,
    employementInfo,
    resumeScreens,
  } = useSelector((state) => state.resumeOptimizer);

  const otheresResumeData = useSelector((state) => state.resumeOptimizer);

  const handleClick = (id) => {
    dispatch(setCurrentResumePage(id));
  };

  return (
    <div style={{ padding: "0px 15px" }}>
      <div
        style={{
          position: "relative",
          borderBottom: "2px solid rgba(38, 28, 75, 0.15)",
          borderLeft: "0px",
          borderRight: "0px",
          paddingBottom: "25px",
        }}
      >
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          {isEdit ? (
            <EditButton handleClick={() => handleClick("personal_info")} />
          ) : null}
        </div>
        <p className="" style={{
          color: '#4CA751',
          fontSize: "14px",
          fontWeight: 600,
          margin: "20px 0px",
          // textAlign: "center",
          position: "relative",
        }}>PERSONAL INFO </p>
        <div style={{ fontWeight: 600, color: "#606060", fontSize: "18px" }}>
          {contactInfo.firstName} {contactInfo.lastName}
        </div>
        <div>
          <div>
            {contactInfo.city} {contactInfo.state ? <>,</> : null}{" "}
            {contactInfo.state} {contactInfo.country ? <>,</> : null}{" "}
            {contactInfo.postalCode}
          </div>
          <div>
            {contactInfo.email} {contactInfo.phoneNumber ? <>,</> : null}{" "}
            {contactInfo.phoneNumber}
          </div>
          <div>{contactInfo.onlineProfile1}</div>
        </div>
      </div>

      {summary && summary != "<p></p>" ? (
        <>
          <div
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#4389FA",
              margin: "20px 0px",
              // textAlign: "center",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "-10px", right: 0 }}>
              {isEdit ? (
                <EditButton handleClick={() => handleClick("summary")} />
              ) : null}
            </div>
            <p className="" style={{ color: '#4CA751' }}>SUMMARY </p>
          </div>
          <div
            style={{ fontSize: "12px", textAlign: "justify", }}
            dangerouslySetInnerHTML={{ __html: summary }}
          />
          <div
            style={{
              border: "0.5px solid rgba(38, 28, 75, 0.15)",
              margin: "25px 0px",
            }}
          />
        </>
      ) : (
        <></>
      )}
      {checkForEmpty(employementInfo.employment) ? (
        <>
          <div
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#4389FA",
              margin: "20px 0px",
              // textAlign: "center",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "-10px", right: 0 }}>
              {isEdit ? (
                <EditButton handleClick={() => handleClick("experience")} />
              ) : null}
            </div>
            <p className="" style={{ color: '#4CA751' }}>EXPERIENCE</p>
          </div>

          {employementInfo.employment.map((item, i) => (
            <div style={{ marginTop: i ? "10px" : "0px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "13px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%", position: "relative" }}>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        marginBottom: "0px",
                        fontWeight: 600,
                      }}
                    >
                      <span>{item.company}</span>
                      <span
                        style={{
                          display:
                            item.company && item.location ? "inline" : "none",
                        }}
                      >
                        ,{" "}
                      </span>
                      <span>{item.location}</span>
                    </p>
                    <div
                      style={{ position: "absolute", right: "0px", top: "0px" }}
                    >
                      {item.from}{" "}
                      {(item.to || item.iscurrentJob) && item.from
                        ? " - "
                        : null}
                      {item.to ? item.to : item.iscurrentJob ? "Present" : null}
                    </div>
                  </div>
                  <i
                    style={{
                      fontWeight: 500,
                      color: "#261C4B",
                    }}
                  >
                    {item.designation}
                  </i>
                </div>
              </div>

              {item.jobDescription && item.jobDescription !== "<p></p>" ? (
                <div
                  style={{ fontSize: "12px", textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: item.jobDescription }}
                ></div>
              ) : null}
              {item.softSkills?.length || item.hardSkills?.length ? (
                <div style={{ marginTop: "-10px" }}>
                  {item.hardSkills?.length ? (
                    <div style={{ fontSize: "12px" }}>
                      <span style={{ fontWeight: 600 }}>Hard Skills : </span>
                      <span>{item.hardSkills.join(", ")}</span>
                    </div>
                  ) : null}
                  {item.softSkills?.length ? (
                    <div style={{ fontSize: "12px" }}>
                      <span style={{ fontWeight: 600 }}>Soft Skills : </span>
                      <span>{item.softSkills.join(", ")}</span>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ))}
          <div
            style={{
              border: "0.5px solid rgba(38, 28, 75, 0.15)",
              margin: "25px 0px",
            }}
          />
        </>
      ) : null}
      {checkForEmpty(educationInfo.education) ? (
        <>
          <div
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#4389FA",
              margin: "20px 0px",
              // textAlign: "center",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "-10px", right: 0 }}>
              {isEdit ? (
                <EditButton handleClick={() => handleClick("education")} />
              ) : null}
            </div>
            <p className="" style={{ color: '#4CA751' }}>  EDUCATION</p>
          </div>

          {educationInfo.education.map((item) => (
            <div
              style={{
                marginBottom: "5px",
                width: "100%",
                fontSize: "13px",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: "13px",
                    marginBottom: "10px",
                    color: "#4CA751",
                  }}
                >
                  {item.field}
                </span>
                <span
                  style={{
                    display: item.field && item.school ? "inline" : "none",
                  }}
                >
                  {" "}
                  |{" "}
                </span>
                <span>{item.school}</span>
                <span
                  style={{
                    display: item.from || item.to ? "inline" : "none",
                  }}
                >
                  {" "}
                  |{" "}
                </span>
                <span>
                  {item.from}
                  {item.to && item.from ? " to " : null} {item.to}
                </span>
              </div>
            </div>
          ))}
          <div
            style={{
              border: "0.5px solid rgba(38, 28, 75, 0.15)",
              margin: "25px 0px",
            }}
          />
        </>
      ) : null}
      {resumeScreens.map((item) => (
        <div>
          {typeof otheresResumeData[item.id] === "string" &&
            otheresResumeData[item.id] ? (
            <>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#4CA751",
                  margin: "20px 0px",
                  // textAlign: "center",
                  position: "relative",
                }}
              >
                <div style={{ position: "absolute", top: "-10px", right: 0 }}>
                  {isEdit ? (
                    <EditButton handleClick={() => handleClick(item.id)} />
                  ) : null}
                </div>
                {item.id}
              </div>
              <div
                style={{ fontSize: "12px", textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: otheresResumeData[item.id] }}
              ></div>
              <div
                style={{
                  border: "0.5px solid rgba(38, 28, 75, 0.15)",
                  margin: "25px 0px",
                }}
              />
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default Template1;
