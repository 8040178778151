import React from "react";
import { Select } from "antd";
import { Field } from "formik";
import "./commonInput.scss";

const { Option } = Select;

const CommonInput = ({
  formField,
  label,
  name,
  placeholder,
  value,
  handleChange,
  nonEditable,
  style,
  type,
  options,
  disabled,
  validate,
}) => {
  return (
    <div className="Common_input_container" style={style}>
      {formField ? (
        <Field
          name={name}
          placeholder={placeholder}
          type={type}
          validate={validate}
        />
      ) : (
        <div>
          {type === "select" ? (
            <>
              {/* <div className="label">{label}</div> */}
              <Select
                defaultValue={value || undefined}
                onChange={handleChange}
                className="companyInput"
                placeholder={placeholder}
              >
                {options.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </>
          ) : (
            <>
              {type === "textarea" ? (
                <>
                  {/* <div className="label">{label}</div> */}
                  <textarea
                    className="companyInput"
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={nonEditable || disabled}
                    style={{ height: "200px" }}
                  />
                </>
              ) : (
                <>
                  {/* <div className="label">{label}</div> */}
                  <input
                    className="companyInput"
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={nonEditable || disabled}
                    type={type}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CommonInput;
