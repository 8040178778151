/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import { Spin } from "antd";
import { Auth } from "aws-amplify";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import * as RegisterActions from "../../store/newRegister/registerActions";
import history from "../../routes/History";
import UnnlockLayout from "../layout";
import UploadResume from "../NewRegistration/uploadResume";

const SocialRegistrationContainer = () => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const registerState = useSelector((state) => state.register);
  const [user, setUser] = useState({});
  const [showUploadResume, setUploadResume] = useState(false);

  const getLoginType = (userInfo) => {
    let loginType = "";
    // check for google first
    loginType = get(
      userInfo,
      "signInUserSession.idToken.payload.identities[0].providerName",
      ""
    );
    if (isEmpty(loginType)) {
      // check for FB
      loginType = "Facebook";
    }
    return loginType;
  };

  const getIdToken = (userInfo) => {
    let idToken = "";
    // check for google first
    idToken = get(userInfo, "signInUserSession.idToken.jwtToken", "");
    if (isEmpty(idToken)) {
      // check for FB
      idToken = "";
    }
    return idToken;
  };

  const getUserName = (userInfo) => {
    let userName = "";
    userName = get(userInfo, "attributes.name", "");
    if (isEmpty(userName)) {
      // check for FB
      userName = "";
    }
    return userName;
  };

  const getUserEmail = (userInfo) => {
    let userEmail = "";
    userEmail = get(userInfo, "attributes.email", "");
    if (isEmpty(userEmail)) {
      // check for FB
      userEmail = "";
    }
    return userEmail;
  };

  const parseResume = (data) => {
    const postData = {
      resData: data,
      userId:
        loginState.userData &&
        loginState.userData.message === "USER NOT PRESENT"
          ? registerState.dataAfterRegister.user_id
          : loginState.userData.user_id,
    };
    dispatch(RegisterActions.uploadResumeRequest(postData));
  };

  useEffect(() => {
    if (
      loginState.userData &&
      loginState.userData.message === "USER NOT PRESENT"
    ) {
      dispatch(
        RegisterActions.socialRegisterRequest({
          first_name: getUserName(user),
          last_name: getUserName(user),
          email: getUserEmail(user),
          primary_resume: "",
          auth_type: getLoginType(user).toUpperCase(),
          idToken: getIdToken(user),
        })
      );
    }
  }, [loginState]);

  useEffect(() => {
    if (loginState.userData && loginState.userData.primary_resume) {
      if (!showUploadResume) history.push("/dashboard");
    } else if (loginState.userData && !loginState.userData.primary_resume) {
      setUploadResume(true);
      // localStorage.clear();
    }
  }, [loginState.userData]);

  useEffect(() => {
    if (registerState.socialRegisterSuccess) {
      setUploadResume(true);
    }
  }, [registerState]);

  useEffect(() => {
    if (loginState.userData.primary_resume) {
      // History.push("/dashboard");
    } else if (loginState.userData && !loginState.userData.primary_resume) {
      setUploadResume(true);
    }
  }, [loginState]);

  useEffect(() => {
    Auth.configure();

    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((userData) => {
        localStorage.setItem("unnlockUserToken", getIdToken(userData));
        dispatch(LoginActions.getUserDataRequest());
        setUser(userData);
      })
      .catch((error) => {
        // console.log("Not signed in", error);
      });
  }, []);

  return (
    <UnnlockLayout
      dontDisplayHeader={true}
      // optionalHeader={
      //   <div
      //     style={{
      //       display: "flex",
      //       justifyContent: "space-between",
      //       alignItems: "center",
      //       padding: "12px 30px",
      //       background: "#4389FA",
      //     }}
      //   >
      //     <div style={{ color: "white", fontSize: "18px", fontWeight: 700 }}>
      //       unnlock
      //     </div>
      //   </div>
      // }
    >
      {showUploadResume ? <UploadResume parseResume={parseResume} /> : <></>}
    </UnnlockLayout>
  );
};

export default SocialRegistrationContainer;
