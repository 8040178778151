/* eslint-disable */
import React from "react";
import { Row, Col, Space, Divider } from "antd";
import messages from "../../assets/strings/messages";
import "./style.scss";
import MiinedButton from "../../components/Button";
import google from "../../assets/images/register/googleIcon.svg";
import facebook from "../../assets/images/register/FBIcon.png";
import { Auth, Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: "us-east-2:1bb90422-d3b8-47dd-a6f0-0574f5fb0368",

    // REQUIRED - Amazon Cognito Region
    region: "us-east-2",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "us-east-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-2_lNhNeCKeU",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "22smlc15lcgfb0igqlaam9kfo1",

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "unnlock-qa.auth.us-east-2.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
      responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const SocialRegistration = ({ fromLogin }) => {
  const handleGoogleRegistration = () => {
    Auth.federatedSignIn({ provider: "Google" });
  };

  const handleFaceBookRegistration = () => {
    Auth.federatedSignIn({ provider: "Facebook" });
  };

  return (
    <Row justify="space-between" style={{ marginBottom: "20px" }}>
      {fromLogin ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ textAlign: "right", width: "50%" }}>
              <img
                src={google}
                alt={messages.google}
                onClick={() => handleGoogleRegistration()}
                style={{ cursor: "pointer", width: "50%" }}
              />
            </div>
            <div style={{ width: "50%" }}>
              <img
                src={facebook}
                alt={messages.facebook}
                onClick={() => handleFaceBookRegistration()}
                style={{ cursor: "pointer", width: "25%", marginLeft: "5px" }}
              />
            </div>
          </div>
          <Divider style={{ margin: "5px 0px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                marginBottom: "-10px",
                textAlign: "center",
              }}
            >
              OR
            </p>
          </Divider>
        </>
      ) : (
        <>
          <Col span={1}>
            <div className="devider_custom">
              <p>Or</p>
            </div>
          </Col>
          <Col span={20}>
            <Row justify="center" className="social-section">
              <Space
                size={12}
                direction="vertical"
                style={{ width: "100%", textAlign: "center" }}
              >
                <p>Fill in details from</p>
                <MiinedButton
                  type="image"
                  icon={<img src={google} alt={messages.google} />}
                  onClick={() => handleGoogleRegistration()}
                />
                <MiinedButton
                  type="image"
                  icon={<img src={facebook} alt={messages.facebook} />}
                  onClick={() => handleFaceBookRegistration()}
                />
              </Space>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};

export default SocialRegistration;
