/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Layout } from "antd";
import { Select } from "antd";

import "./JobMatch.scss";
import arrowLeft from "../../assets/images/WhiteArrowLeft.svg";
import dropdownArrow from "../../assets/images/componies/dropdownArrow.svg";
import SearchInput from "../Dashboard/searchField";
import history from "../../routes/History";
import ArrowLeft from "../../assets/images/componies/ArrowLeft.svg";
import * as JobMatchAction from "../../store/jobMatch/jobMatchActions";
import JobMatchResultsContainer from "../JobMatchResults/JobMatchResultsContainer";
import CommonInput from "../ICDProvider/commonInput";
import { getCompanyFilteresRequest } from "../../store/dashBoard/dashboardActions";
import {
  getDashboardComponyDeatailsFailure,
  getDashboardComponyDeatailsRequest,
} from "../../store/componies/action";
import { getResumeVaultsRequest } from "../../store/resumeOptimizer/resumeOptimizerActions";
import IndustryDropDown from "../ICDProvider/industryDropDown";
import UnnlockLayout from "../layout";
import unnlockNotification from "../../components/notification";
import { PreviewSuggestionWithoutInfo } from "../ResumeOpimizer/suggestions";

const { Option } = Select;
const { Content } = Layout;

const optionsEducation = [
  { value: undefined, title: "Select Education" },
  { value: "Masters", title: "Masters" },
  { value: "PHD", title: "PHD" },
  { value: "Bachelors", title: "Bachelors" },
];

const optionsExperience = [
  { value: undefined, title: "Select Experience" },
  { value: 1, title: "1 Years" },
  { value: 5, title: "5 Years" },
  { value: 10, title: "10 Years" },
  { value: 15, title: "15 Years" },
  { value: "20+", title: "20+ Years" },
];

const JobMatch = (props) => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const { loader, jobMatchResultData } = useSelector((state) => state.jobmatch);
  // const jobMatchFetch = useSelector(
  //   (state) => state.jobmatch.jobMatchFetchData
  // );
  const [clickCheckJob, setclickCheckJob] = useState(false);
  const [showJMResults, setshowJMResults] = useState(false);
  const [showJMForm, setshowJMForm] = useState(false);
  const [currentResume, setcurrentResume] = useState("0");
  const [initialFormData, setinitialFormData] = useState({
    comp_name: "",
    comp_website: "",
    job_title: "",
    education: "",
    experience: "",
    relocating: true,
    job_desc: "",
  });

  // const [tableData, setTableData] = useState([]);

  const companyDetails = useSelector(
    (state) => state.componies.dashboardCompanyDetails
  );
  const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);

  // const jobTrackerId = props.location?.state?.jobTrackerId;

  const handleChange = (e) => {
    setinitialFormData({ ...initialFormData, [e.target.name]: e.target.value });
  };

  const handleJobCheck = () => {
    const primaryResumeId = currentResume;

    if (currentResume == null || currentResume == "0") {
      unnlockNotification("please select a resume", "warning");
    } else {
      let compName = "";
      if (companyDetails && companyDetails?.length) {
        compName = companyDetails[0]?.company;
      }
      let postdata = {
        resumeId: primaryResumeId,
        data: {
          primary_resume: primaryResumeId,
          company_name:
            initialFormData.comp_name != ""
              ? initialFormData.comp_name
              : "Infosys",
          company_url: initialFormData.comp_website,
          job_title: initialFormData.job_title,
          education:
            initialFormData.education != "" ? initialFormData.education : "N/A",
          required_experience:
            optionsExperience.filter(
              (item) => item.title === initialFormData.experience
            )[0]?.value || 5,
          text: initialFormData.job_desc,
          industry:
            initialFormData.industry != ""
              ? initialFormData.industry
              : "Information Technology & Services",
        },
      };
      if (showJMResults) {
        postdata = {
          ...postdata,
          data: {
            ...postdata.data,
            job_match_id: jobMatchResultData?.job_match_id,
          },
        };
      }
      setclickCheckJob(true);
      dispatch(
        JobMatchAction.jobMatchResultsRequest({ postdata, donotSave: false })
      );
    }
  };

  const handleSelect = (e) => {
    if (e.value) {
      dispatch(
        getDashboardComponyDeatailsRequest({
          // company_name: e.text,
          unnlock_id: e.value,
        })
      );
    } else {
      setinitialFormData({ ...initialFormData, comp_name: e.text });
    }
  };

  useEffect(() => {
    if (Object.keys(jobMatchResultData).length !== 0 && clickCheckJob) {
      // history.push("/jobmatchresults");
      setshowJMResults(true);
      setshowJMForm(false);
    }
  }, [jobMatchResultData]);

  useEffect(() => {
    dispatch(getDashboardComponyDeatailsFailure());
    dispatch(getCompanyFilteresRequest());
  }, []);

  useEffect(() => {
    dispatch(getResumeVaultsRequest());
    if (loginState.userData && loginState.userData.primary_resume) {
      dispatch(JobMatchAction.getJobTrackeresRequest({ limit: "all" }));
    }
  }, [loginState.loader]);

  useEffect(() => {
    if (companyDetails && companyDetails?.length) {
      setinitialFormData({
        ...initialFormData,
        comp_name: companyDetails[0]?.company,
        // comp_website: companyDetails[0]?.company_web_url,
        industry: companyDetails[0]?.industry,
      });
    }
  }, [companyDetails]);

  // handle form Open jobs

  const fromOpenJobs = props.location?.state?.fromOpenJobs;
  // const doNotSave = props.location?.state?.doNotSave;

  useEffect(() => {
    if (fromOpenJobs) {
      const { data, doNotSave } = props.location?.state?.postdata;
      const postdata = {
        resumeId: data.primary_resume,
        data: {
          primary_resume: data.primary_resume,
          company_name: data.company_name,
          company_url: data.company_url,
          job_title: data.job_title,
          education: data.education,
          required_experience: data.required_experience,
          text: data.text,
          open_job_id: data.open_job_id,
          industry: data.industry,
          job_match_id: data?.job_match_id,
        },
      };
      setinitialFormData({
        comp_name: data.company_name,
        comp_website: data.company_url,
        job_title: data.job_title,
        education: data.education,
        experience: data.required_experience,
        industry: data.industry,
        relocating: true,
        job_desc: data.text,
      });
      setcurrentResume(data.primary_resume);
      setclickCheckJob(true);
      dispatch(
        JobMatchAction.jobMatchResultsRequest({
          postdata,
          donotSave: doNotSave ? true : false,
        })
      );
    }
  }, [fromOpenJobs]);

  return (
    <UnnlockLayout>
      <div className="jobMatchContainer">
        <Content>
          <Row>
            <Col md={24}>
              <div className="jobMactFormTitle">
                <div
                  style={{
                    color: "#4389fa",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {fromOpenJobs ? (
                    <div
                      onClick={() => {
                        history.push("/OpenJobComapnies");
                        dispatch(JobMatchAction.jobMatchFetchFailure());
                      }}
                    >
                      <img
                        src={ArrowLeft}
                        alt="arrow"
                        style={{
                          marginBottom: "4px",
                          width: "20px",
                          marginRight: "8px",
                          cursor: "pointer",
                        }}
                      />
                      <span>Open Jobs |</span>
                    </div>
                  ) : null}
                  <div style={{ marginLeft: "5px" }}>Job Match</div>
                </div>
                <div className="check_tracker_btn_container">
                  <div>View matches in Job Tracker</div>
                  <button
                    type="submit"
                    className="check_tracker_btn"
                    onClick={() => {
                      history.push("/jobTracker");
                    }}
                  >
                    <img src={arrowLeft} alt="check" />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {showJMResults && !showJMForm ? (
              <div className="contentHeaderContainer">
                <div className="title">
                  {initialFormData.comp_name} | {initialFormData.job_title}
                </div>
                <div
                  className="detailsButton"
                  onClick={() => {
                    setshowJMForm(true);
                  }}
                >
                  View Details{" "}
                  <span>
                    <img src={dropdownArrow} />
                  </span>
                </div>
              </div>
            ) : (
              <Row style={{ width: "100%" }}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    paddingBottom: "14px",
                  }}
                ></Row>
                <Row justify="space-between" gutter={{ sx: 8, sm: 4, md: 8 }}>
                  <Col xs={24} sm={24} md={17}>
                    <div className="contentContainer">
                      <Row
                        justify="space-between"
                        gutter={[24, 2]}
                        style={{ alignItems: "center" }}
                      >
                        <Col
                          // md={8}
                          xs={24}
                          sm={8}
                          md={8}
                          lg={8}
                          className="selectResume"
                          // style={{ marginTop: "-32px" }}
                        >
                          <Select
                            value={currentResume}
                            defaultValue="Select Resume"
                            onChange={(e) => {
                              setcurrentResume(e);
                            }}
                            className="customeselectResume"
                          >
                            <Option key="0">Select Resume</Option>
                            {resumeOptimizerState.resumeVaults.map((i) => (
                              <Option key={i.resume_id}>{i.file_name}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col
                          xs={24}
                          sm={8}
                          md={8}
                          lg={8}
                          // md={8}
                          // style={{ paddingLeft: "15px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ margin: "0px 0 0px" }}>
                              <CommonInput
                                name="comp_website"
                                placeholder="URL for Original Job Posting"
                                value={initialFormData.comp_website}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={[24, 2]}>
                        <Col xs={24} sm={12} md={12} lg={12}>
                          <SearchInput
                            jobMatchSearch
                            defaultValue={initialFormData.comp_name}
                            className="textInput"
                            name="comp_name"
                            placeholder="Target Company Name"
                            onChange={handleSelect}
                            style={{
                              border: "1px solid #dee5ff",
                              padding: "10px 15px 6px",
                              borderRadius: "8px",
                            }}
                            setselectedComp={() => {}}
                            initialEmpty
                          />
                        </Col>

                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          //  style={{ paddingLeft: "15px" }}
                        >
                          <CommonInput
                            name="job_title"
                            placeholder="Enter Job Title"
                            style={
                              {
                                // paddingBottom: "29px",
                              }
                            }
                            value={initialFormData.job_title}
                            handleChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[24, 2]}>
                        <Col xs={24} sm={12} md={8} lg={8}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              <CommonInput
                                placeholder="Education Required"
                                name="education"
                                type="select"
                                value={initialFormData.education}
                                handleChange={(e) => {
                                  let obj = {
                                    target: {
                                      name: "education",
                                      value: e,
                                    },
                                  };
                                  handleChange(obj);
                                }}
                                options={optionsEducation.map(
                                  (item) => item.title
                                )}
                                style={{
                                  border: "1px solid #dee5ff",
                                  marginBottom: "13px",
                                  padding: "8px 15px",
                                  borderRadius: "8px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={24}
                          sm={8}
                          md={8}
                          lg={8}
                          // md={8}
                          //  style={{ paddingLeft: "15px" }}
                        >
                          <IndustryDropDown
                            placeholder="Industry"
                            value={initialFormData.industry}
                            handleChange={(e) => {
                              let obj = {
                                target: {
                                  name: "industry",
                                  value: e,
                                },
                              };
                              handleChange(obj);
                            }}
                            styles={{
                              border: "1px solid #dee5ff",
                              marginBottom: "13px",
                              padding: "10px 15px 6px",
                              borderRadius: "8px",
                            }}
                          />
                        </Col>

                        <Col
                          xs={24}
                          sm={8}
                          md={8}
                          lg={8}
                          // md={8}
                          //  style={{ paddingLeft: "15px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              <CommonInput
                                placeholder="Experience Required"
                                name="experience"
                                type="select"
                                value={initialFormData.experience}
                                handleChange={(e) => {
                                  let obj = {
                                    target: {
                                      name: "experience",
                                      value: e,
                                    },
                                  };
                                  handleChange(obj);
                                }}
                                options={optionsExperience.map(
                                  (item) => item.title
                                )}
                                style={{
                                  border: "1px solid #dee5ff",
                                  marginBottom: "13px",
                                  padding: "8px 15px",
                                  borderRadius: "8px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} md={24}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ margin: "10px 0 0px" }}>
                              <CommonInput
                                name="job_desc"
                                placeholder="Copy and paste the job description here..."
                                value={initialFormData.job_desc}
                                handleChange={handleChange}
                                type="textarea"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Col>
                          <button
                            type="submit"
                            className="check_btn"
                            onClick={() => {
                              handleJobCheck();
                            }}
                          >
                            <p>{loader ? "Loading..." : "Match"}</p>
                            <img src={arrowLeft} alt="check" />
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={7}>
                    <div className="resume_optimizer_body_right">
                      <PreviewSuggestionWithoutInfo page="jobMatch" />
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
          </Row>
          {showJMResults ? (
            <JobMatchResultsContainer handleEdit={initialFormData} />
          ) : null}
        </Content>
      </div>
    </UnnlockLayout>
  );
};

export default JobMatch;
