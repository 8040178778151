import React, { useEffect } from "react";
import UnnlockLayout from "../layout";
import { Row, Col } from "antd";
import Gear from "../../assets/images/dashboard/Gear.svg";
import document from "../../assets/images/landing/document.svg";
import titleImage from "../../assets/images/UnnlockNew.svg";
import resumeOptimizer from "../../assets/images/landing/resumeOptimizer.svg";
import companyMatch from "../../assets/images/landing/companyMatch.svg";
import jobMatch from "../../assets/images/landing/jobMatch.svg";
import journey from "../../assets/images/landing/journey.svg";
import howItWorks from "../../assets/images/landing/howItWorks.svg";
import tickcircle from "../../assets/images/landing/tick-circle.svg";
import unlockwhitewithoubg from "../../assets/images/landing/unlockwhitewithoubg.svg";
import history from "../../routes/History";
import "./Landing.scss";
import landingLines from "../../assets/images/landing/landingimgline.svg";
import landingPerson from "../../assets/images/landing/landingimgguy.svg";
import Optimizer from "../../assets/images/dashboard/optimizer.svg";
import CompanyMatch from "../../assets/images/dashboard/companyMatch.svg";
import JobMatch from "../../assets/images/dashboard/jobMatch.svg";
import explore from "../../assets/images/dashboard/explore.svg";
import { useState } from "react";
import LandingFooter from "./LandingFooter";
import {
  CloseOutlined
} from '@ant-design/icons';


const Landing = () => {
  const [displayUplaod, setdisplayUplaod] = useState(0);
  const [opensideBar, setOpensideBar] = useState(false)
  const plans = [
    { title: "Free", cost: "$0", costDescription: "", bgColor: "#0099001A" },
    {
      title: "Basic",
      cost: "$10",
      costDescription: "$ 8.50 if billed annually",
      bgColor: "#E251511A",
    },
    {
      title: "Premium",
      cost: "$15",
      costDescription: "$ 12.50 if billed annually",
      bgColor: "#E6A93D33",
    },
  ];
  const planCard = ({ title, cost, costDescription, bgColor }) => {
    return (
      <div className="card">
        <div className="body">
          <div style={{ minHeight: "105px" }}>
            <div className="plan" style={{ background: bgColor }}>
              {title}
            </div>
            <div className="cost">{cost}</div>
            <div className="costDescription">{costDescription}</div>
          </div>
          <div className="devider" />
          <div className="title">This plan includes</div>
          <div className="descriptionItem">
            <img src={tickcircle} />
            Feature
          </div>
          <div className="descriptionItem">
            <img src={tickcircle} />
            Feature
          </div>
          <div className="descriptionItem">
            <img src={tickcircle} />
            Feature
          </div>
        </div>
        <div className="footer">Get Started</div>
      </div>
    );
  };

  const landingPageCards = (icon, titleLand, descLand) => {
    return (

      <div className="cardLanding">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: "48px", marginRight: "16px" }} src={icon} />
          <p className="landTitle">{titleLand}</p>
        </div>
        <p className="landDesc">{descLand}</p>
      </div>


    );
  };

  useEffect(() => {
    window.onscroll = function () {
      setdisplayUplaod(window.pageYOffset);
    };
  }, []);

  return (
    <div>
      <div className="landingPage_header">
        <div className="landingPage_header_inner">
          <div className="mobile_heeader">
            <img
              className="mobileunnlockIcon"
              src={titleImage}
              alt="titleImage"
            />
            <div className='hambugericon'>
              <div className="cicleshape" onClick={() => setOpensideBar(true)}>
                <img src={Gear} alt="alt" />
              </div>
            </div>
            <div
              className="loginsigninbutton"
              onClick={() => {
                history.push("/newRegistration");
              }}
              style={{ cursor: "pointer" }}
            >
              Login / SIGN IN
            </div>
          </div>
          <img
            className="unnlockIcon"
            src={titleImage}
            alt="titleImage"
            style={{ width: "10%" }}
          />
          <div className="lists">
            <a href="#features" className="listitem">
              Features
            </a>
            <a href="#howItWorks" className="listitem">
              How It Works
            </a>
            <a href="#chooseYourPlan" className="listitem">
              Pricing
            </a>
          </div>
          <div
            onClick={() => {
              history.push("/newRegistration");
            }}
            style={{ cursor: "pointer" }}
            className="loginBtn"
          >
            Login / SIGN IN
          </div>
        </div>
      </div>
      <UnnlockLayout dontDisplayHeader={true}>
        <div className="landingPage">
          <div className="landingBody">
            <div>
              <p className="mainTitle">Job search done right</p>
              <p className="mainsubtitle">unnlock the <strong>right resumes</strong> for the <strong>right jobs</strong> at the <strong>right companies</strong></p>
              <div className="jobTitle" />
              <div className="landingLine" />
              <Row gutter={[{ sm: 32, lg: 32 }, { sm: 32, md: 32, lg: 8 }]} >
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
                  {landingPageCards(
                    CompanyMatch,
                    "Company Match",
                    "Discover your top employers with AI insights into skill and hiring trends"
                  )}
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
                  {landingPageCards(
                    JobMatch,
                    "Job Match",
                    "No wasted effort. Check the fit before you apply"
                  )}
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
                  {landingPageCards(
                    Optimizer,
                    "Resume Optimizer",
                    "Fix critical resume errors with AI and ATS integrations"
                  )}
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
                  {landingPageCards(
                    explore,
                    "Open jobs",
                    "Review job openings that fit your goals, not your last title"
                  )}
                </Col>
              </Row>
            </div>
            <div id="howItWorks" className="companyMatch">
              <div className="description">
                <div className="title">How It Works</div>
                <div style={{ marginBottom: "16px" }}>Job search, meet data.</div>
                <div className="subtitle">
                  unnlock is taking the science of job search deeper to deliver:
                </div>
                <ul style={{ padding: "16px" }} className='dresponseview'>
                  <li style={{ marginBottom: "16px" }}>
                    <p>
                      Personalized insights from AI analysis of your skills and workforce trends
                    </p>
                  </li>
                  <li style={{ marginBottom: "16px" }}>
                    <p>
                      Guidance on where to work based on multi-year hiring patterns
                    </p>
                  </li>
                  <li>
                    <p>
                      The best jobs and resume fixes for you based on machine learning analysis of 150M work profiles
                    </p>
                  </li>
                </ul>
              </div>
              <img src={howItWorks} alt="howItWorks" />
              <ul style={{ padding: "16px" }} className='resonsiveultext'>
                <li style={{ marginBottom: "8px" }}>
                  <p>
                    Company matches based on hiring patterns over several
                    years
                  </p>
                </li>
                <li>
                  <p>
                    Skills analysis from over 150m profiles to recommend jobs
                    and companies
                  </p>
                </li>
              </ul>
            </div>

            <div className="companyMatch">
              <div className="description">
                <div className="title">Company Match</div>
                <div style={{ marginBottom: "16px" }}>Right matches for you</div>
                <div className="subtitle">
                  Upload your resume to see up to 100 potential employers based on multi-year hiring trends personalized to your skills and experience
                </div>
                <ul style={{ padding: "16px" }} className='dresponseview'>
                  <li style={{ marginBottom: "8px" }}>
                    <p>See how your skills stack up against other company employees</p>
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <p>Track companies and view insights into their jobs, skills, and employee qualifications</p>
                  </li>
                </ul>
              </div>
              <img src={companyMatch} alt="companyMatch" />
              <ul style={{ padding: "16px" }} className='resonsiveultext'>
                <li style={{ marginBottom: "8px" }}>
                  <p>Review companies for skill sets, titles and locations</p>
                </li>
                <li style={{ marginBottom: "8px" }}>
                  <p>Save your favorite companies to track and apply</p>
                </li>
                <li>
                  <p>
                    Customize your Resume to skill sets sought by the company
                    of your interest
                  </p>
                </li>
              </ul>
            </div>

            <div className="resumeOptimizer">
              <ul style={{ padding: "16px" }} className='resonsiveultext'>
                <li style={{ marginBottom: "8px" }}>
                  <p>Match your skills to any job description</p>
                </li>
                <li style={{ marginBottom: "8px" }}>
                  <p>
                    Write better Resumes with suggestions from OpenAI  and
                    Grammarly
                  </p>
                </li>
                <li>
                  <p>
                    Customize your Resume to Job Descriptions to increase
                    match rate
                  </p>
                </li>
              </ul>
              <img src={jobMatch} alt="jobMatch" />
              <div className="description">
                <div className="title">Job Match</div>
                <div style={{ marginBottom: "16px" }}>Apply to the right jobs</div>
                <div className="subtitle">
                  Instantly see how your resume stacks up against what’s hiding in the job posting.
                </div>
                <ul style={{ padding: "16px" }} className='dresponseview'>
                  <li style={{ marginBottom: "8px" }}>
                    <p>See the gaps in your resume before you apply</p>
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <p>
                      Add missing skills to your resume
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="resumeOptimizer">
              <ul style={{ padding: "16px" }} className="resonsiveultext">
                <li style={{ marginBottom: "8px" }}>
                  <p>
                    Most companies, including a  majority of the Fortune 500
                    companies use ATS
                  </p>
                </li>
                <li style={{ marginBottom: "8px" }}>
                  <p>Write better Resumes with suggestions from OpenAI</p>
                </li>
                <li>
                  <p>
                    Customize your Resume to Job Descriptions to increase
                    match rate
                  </p>
                </li>
              </ul>
              <img src={resumeOptimizer} alt="resumeOptimizer" />
              <div className="description">
                <div className="title">Resume Optimizer</div>
                <div style={{ marginBottom: "16px" }}>Right resume for the right job</div>
                <div className="subtitle">
                  Boost for your Interview chances with a Resume designed for Applicant Tracking System (ATS) screening
                </div>
                <ul style={{ padding: "16px" }} className="dresponseview">
                  <li style={{ marginBottom: "8px" }}>
                    <p>
                      Quickly convert your resume to an ATS-friendly format
                    </p>
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <p>Fix errors and improve your resume writing with assistance from Grammarly and OpenAI</p>
                  </li>
                  <li>
                    <p>
                      Applicant tracking system (ATS) tester and preview
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <LandingFooter />
          {/* <div className="landingFooter">
            <div className="address">
              <img
                src={unlockwhitewithoubg}
                alt="unnlock"
                style={{ width: "150px" }}
              />
              <div className="details">
                Address: more other job offers Lorem ipsum dolor sit amet,
                consectetur adipiscing{" "}
              </div>
              <div>© 2020 unnlock. All rights reserved.</div>
            </div>
            <div className="sitemap">
              <div className="title">SITEMAP</div>
              <div>Features</div>
              <div>How It Works</div>
              <div>Pricing</div>
            </div>
            <div className="loginSection">
              <div>
                Have an account?
                <span
                  onClick={() => {
                    history.push("/newRegistration");
                  }}
                >
                  LOGIN
                </span>
              </div>
              <div style={{ margin: "5px 0px" }}>Or</div>
              <div>
                New to unlock?
                <span
                  onClick={() => {
                    history.push("/newRegistration");
                  }}
                >
                  SIGN IN
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </UnnlockLayout>
      {displayUplaod > 100 ? (
        <div className="uploadFixed" id="uploadFixed">
          <div>Upload your resume and take a tour of our features</div>
          <button
            onClick={() => {
              history.push("/preUpload");
            }}
          >
            <img src={document} alt="document" />
            Upload Resume
          </button>
        </div>
      ) : null}
      {opensideBar ?
        <div className="sidemenuSliderwrap">
          <div className="closeIcon" onClick={() => setOpensideBar(false)}>
            <CloseOutlined />
          </div>
          <div className="siderbarmenucontanier">
            <a href="#features" className="listitem" onClick={() => setOpensideBar(false)}>
              Features
            </a>
            <a href="#howItWorks" className="listitem" onClick={() => setOpensideBar(false)}>
              How It Works
            </a>
            <a href="#chooseYourPlan" className="listitem" onClick={() => setOpensideBar(false)}>
              Pricing
            </a>

            <div
              onClick={() => {
                history.push("/newRegistration");
              }}
              style={{ cursor: "pointer" }}
              className="loginBtn"
            >
              Login / SIGN IN
            </div>

          </div>
        </div>
        : ''}
    </div>
  );
};

export default Landing;
