import React, { useState } from "react";
import "../Explore/index.scss";
import BySkills from "./bySkills";
import { GetOnboardSuggestions } from "../ResumeOpimizer/suggestions";

const ExploreComp = () => {
  return (
    <div className="explore_comp">
      <div className="Exploretitle">Open Jobs</div>
      <div className="exploreComp_container">
        <div className="card_conatiner">
          <BySkills />
        </div>
        <div className="exploreComp-whycontainer">
          <GetOnboardSuggestions screen="exploreBySkills" />
        </div>
      </div>
    </div>
  );
};

export default ExploreComp;
