import React from "react";
import JobTracker from "../PersonalDashboard/jobTracker";
import UnnlockLayout from "../layout";
import "../PersonalDashboard/index.scss";

const Tracker = () => {
  return (
    <UnnlockLayout>
      <div className="personalDashboard_container" style={{ padding: "30px" }}>
        <JobTracker handleViewAll={() => {}} viewJTAll={true} />
      </div>
    </UnnlockLayout>
  );
};

export default Tracker;
