/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Skeleton, Tabs } from "antd";
import "../Profile/model.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  resumeRequest,
  resumeUpdateRequest,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import { formatToEmployementData } from "../../utils/helper";
import {
  jobMatchFetchRequest,
  showUpdateSkillsModal,
} from "../../store/jobMatch/jobMatchActions";
import MiinedButton from "../../components/Button";

const UpdateSkillsModal = ({ visible, unnlockJMUnamatchedSkills }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [slectedSoftSkills, setslectedSoftSkills] = useState([]);
  const [slectedHardSkills, setslectedHardSkills] = useState([]);
  const [suggestedSoftSkills, setSuggestedSoftSkills] = useState([]);
  const [suggestedHardSkills, setSuggestedHardSkills] = useState([]);
  const [currentTab, setcurrentTab] = useState("hardSkills");
  const { employementInfo, loaded, loader, currentResume } = useSelector(
    (state) => state.resumeOptimizer
  );

  const { trackerID } = useSelector((state) => state.jobmatch);
  const dispatch = useDispatch();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch(showUpdateSkillsModal(false));
  };

  const getPostData = (values, saveSkills) => {
    let formData = {
      employment: values.employment.map((item, i) => {
        if (i === 0 && !saveSkills) {
          setslectedSoftSkills(item.softSkills);
          setslectedHardSkills(item.hardSkills);
        }
        return {
          ...formatToEmployementData(item),
          PositionHistory: [
            {
              ...formatToEmployementData(item).PositionHistory[0],
              Description: item.jobDescription || "",
              HardSkills: saveSkills
                ? slectedHardSkills
                : item.hardSkills || [],
              SoftSkills: saveSkills
                ? slectedSoftSkills
                : item.softSkills || [],
              currentStep: 3,
            },
          ],
        };
      }),
    };
    return formData;
  };

  const onChange = (key) => {
    setcurrentTab(key);
  };

  useEffect(() => {
    setIsModalVisible(visible);
    if (currentResume && !loader && visible) dispatch(resumeRequest());
  }, [visible]);

  useEffect(() => {
    if (visible && loaded && employementInfo?.employment?.length && !loader) {
      let formData = getPostData(employementInfo, false);
      dispatch(
        resumeUpdateRequest({
          formData,
          label: "employment-update",
        })
      );
    }
    if (unnlockJMUnamatchedSkills) {
      const skillsObj = { ...unnlockJMUnamatchedSkills };
      setSuggestedSoftSkills(skillsObj.soft_skills);
      setSuggestedHardSkills(skillsObj.hard_skills);
    }
  }, [loaded, visible]);

  useEffect(() => {
    return () => {
      // dispatch(showUpdateSkillsModal(false));
      // setIsModalVisible(false);
      // setslectedSoftSkills([]);
      // setslectedHardSkills([]);
      // setSuggestedSoftSkills([]);
      // setSuggestedHardSkills([]);
    };
  }, []);

  const viewAll = true;

  const updateHardSkills = (
    <div>
      {slectedHardSkills.length ? (
        <div className="selecteddSkills">
          <div className="title">Selected Hard Skills</div>
          <div className="skills">
            {slectedHardSkills.map((item) => (
              <div
                className="skill"
                onClick={() => {
                  setslectedHardSkills(
                    slectedHardSkills.filter((i) => i !== item)
                  );
                  setSuggestedHardSkills([item, ...suggestedHardSkills]);
                }}
              >
                <p>{item}</p>
                <span>+</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="suggestedSkills">
        <div className="title">Suggested Hard Skills</div>
        <div
          className="skills"
          style={{ maxHeight: viewAll ? "300px" : "200px" }}
        >
          {suggestedHardSkills.length ? (
            suggestedHardSkills.map((item, i) => (
              <>
                {i < 8 || viewAll ? (
                  <div
                    className="skill"
                    onClick={() => {
                      setslectedHardSkills([
                        ...slectedHardSkills.filter((prev) => prev !== item),
                        item,
                      ]);
                      setSuggestedHardSkills(
                        suggestedHardSkills.filter((sel) => sel !== item)
                      );
                    }}
                    style={
                      {
                        // color: foundSkills.includes(item) ? "blue" : "#261c4b",
                        // background: foundSkills.includes(item)
                        //   ? "rgba(67, 137, 250, 0.1)"
                        //   : "white",
                      }
                    }
                  >
                    <p>{item}</p>
                    <span>+</span>
                  </div>
                ) : null}
              </>
            ))
          ) : (
            <h3 style={{ textAlign: "center", width: "100%" }}>
              No more hard skills found
            </h3>
          )}
        </div>
      </div>
    </div>
  );
  const updatesoftSkills = (
    <div>
      {slectedSoftSkills.length ? (
        <div className="selecteddSkills">
          <div className="title">Selected Soft Skills</div>
          <div className="skills">
            {slectedSoftSkills.map((item) => (
              <div
                className="skill"
                onClick={() => {
                  setslectedSoftSkills(
                    slectedSoftSkills.filter((i) => i !== item)
                  );
                  setSuggestedSoftSkills([item, ...suggestedSoftSkills]);
                }}
              >
                <p>{item}</p>
                <span>+</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="suggestedSkills">
        <div className="title">Suggested Soft Skills</div>
        <div
          className="skills"
          style={{ maxHeight: viewAll ? "300px" : "200px" }}
        >
          {suggestedSoftSkills.length ? (
            suggestedSoftSkills.map((item, i) => (
              <>
                {i < 8 || viewAll ? (
                  <div
                    className="skill"
                    onClick={() => {
                      setslectedSoftSkills([
                        ...slectedSoftSkills.filter((prev) => prev !== item),
                        item,
                      ]);
                      setSuggestedSoftSkills(
                        suggestedSoftSkills.filter((sel) => sel !== item)
                      );
                    }}
                    style={
                      {
                        // color: foundSkills.includes(item) ? "blue" : "#261c4b",
                        // background: foundSkills.includes(item)
                        //   ? "rgba(67, 137, 250, 0.1)"
                        //   : "white",
                      }
                    }
                  >
                    <p>{item}</p>
                    <span>+</span>
                  </div>
                ) : null}
              </>
            ))
          ) : (
            <h3 style={{ textAlign: "center", width: "100%" }}>
              No more soft skills found
            </h3>
          )}
        </div>
      </div>
    </div>
  );

  const items = [
    {
      key: "hardSkills",
      label: `Hard Skills`,
      children: updateHardSkills,
    },
    {
      key: "softSkills",
      label: `Soft Skills`,
      children: updatesoftSkills,
    },
  ];

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className={
        viewAll ? "custom_addSkillsModal_enlarged" : "custom_addSkillsModal"
      }
      afterClose={handleCancel}
      width={1000}
    >
      <Skeleton loading={loader} active>
        <div className="model_body">
          <div className="content_holder" style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>{updateHardSkills}</div>
              <div style={{ width: "48%" }}>{updatesoftSkills}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "10px 10px 0px",
              }}
            >
              <MiinedButton
                title={loader ? "Loading..." : "Update"}
                onClick={(e) => {
                  let formData = getPostData(employementInfo, true);
                  dispatch(
                    resumeUpdateRequest({
                      formData,
                      label: "employment-update",
                      updateJobmatch: true,
                      trackerID,
                    })
                  );
                  handleCancel();
                }}
              />
            </div>
          </div>
        </div>
      </Skeleton>
    </Modal>
  );
};

export default UpdateSkillsModal;
