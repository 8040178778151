import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import UnnlockLayout from "../layout";
import "./index.scss";
import { GetOnboardSuggestions } from "../ResumeOpimizer/suggestions";
import MiinedButton from "../../components/Button";
import { getChatBotAnswers } from "../../store/Tools/toolsActions";
import * as DashBoardActions from "../../store/dashBoard/dashboardActions";
import { useDispatch, useSelector } from "react-redux";

const questions = [
  "What Companies i should be applying to?",
  "What are top companies that suits my resume?",
];

const UnnlockChatBot = () => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [generetedResumeQuestion, setgeneretedResumeQuestion] = useState("");
  const { loading: loader, chatBotAnswers } = useSelector(
    (state) => state.tools
  );
  const loginState = useSelector((state) => state.login);
  const {
    employementInfo,
    skillsForAtsView: skills,
    resumeParseData: { ExperienceSummaryDetails },
  } = useSelector((state) => state.dashboard);
  const experienceData = ExperienceSummaryDetails?.MonthsOfWorkExperience;

  const onRadioChange = (e) => {
    setQuestion(e.target.value);
  };

  useEffect(() => {
    if (
      loginState.userData &&
      loginState.userData.primary_resume &&
      !employementInfo?.employment[0]?.designation
    ) {
      dispatch(
        DashBoardActions.resumeParserRequest(loginState.userData.primary_resume)
      );
    }
  }, [loginState]);

  useEffect(() => {
    if (experienceData || skills) {
      const years = parseInt(parseInt(experienceData) / 12);
      const months = parseInt(experienceData) - years * 12 || 0;
      let quesStr = `I have ${years} years and ${months} months of experience as ${employementInfo?.employment[0]?.designation} role, `;
      setgeneretedResumeQuestion(quesStr);
    }
  }, [experienceData, skills]);

  return (
    <UnnlockLayout>
      <div className="UnnlockChatBotTitle">Unnlock Chat Bot</div>
      <div className="UnnlockChatBot">
        <div className="UnnlockChatBotConatiner">
          <Radio.Group
            onChange={onRadioChange}
            value={question}
            className="UnnlockChatBotQuestions"
          >
            {questions.map((ques) => (
              <Radio value={ques}>{ques}</Radio>
            ))}
          </Radio.Group>
          <div className="or">Or</div>
          <div className="questionInput">
            <input
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
            <MiinedButton
              title={"Submit"}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  getChatBotAnswers({
                    text: `${generetedResumeQuestion}${question}`,
                  })
                );
                // setShowApplyCouponModal(false);
              }}
              disabled={!question || loader}
            />
          </div>
          <div className="answerSection">
            <textarea
              value={loader ? "Getting answer..." : chatBotAnswers}
              readOnly
            />
          </div>
        </div>
        <div className="UnnlockChatBotTips">
          <GetOnboardSuggestions screen="exploreBySkills" />
        </div>
      </div>
    </UnnlockLayout>
  );
};

export default UnnlockChatBot;
