/*eslint-disable*/
import React, { useEffect } from "react";
import { Field } from "formik";
import { PlusOutlined } from "@ant-design/icons";
import { Select, Input, Button } from "antd";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { useState } from "react";

const { Option } = Select;

const months = [
  { lab: "Jan", val: "01" },
  { lab: "Feb", val: "02" },
  { lab: "Mar", val: "03" },
  { lab: "Apr", val: "04" },
  { lab: "May", val: "05" },
  { lab: "Jun", val: "06" },
  { lab: "Jul", val: "07" },
  { lab: "Aug", val: "08" },
  { lab: "Sep", val: "09" },
  { lab: "Oct", val: "10" },
  { lab: "Nov", val: "11" },
  { lab: "Dec", val: "12" },
];

const ResumeOptimizerInput = ({
  label,
  name,
  type,
  error,
  touched,
  value,
  onlyYear,
  options,
  handleChange,
}) => {
  const x = `${value}`?.includes("/") ? value?.split("/")[0] : "";
  const y = `${value}`?.includes("/") ? value?.split("/")[1] : "";
  const [month, setMonth] = useState(x);
  const [year, setYear] = useState(y);
  const [dropVals, setdropVals] = useState(options);
  const [otheresValue, setotheresValue] = useState("");
  const handleMonth = (value) => {
    setMonth(value);
  };

  console.log(month, year, value, "monthyear");

  let years = [];
  for (let i = 1970; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }

  years = years.reverse();

  const handleYear = (value) => {
    onlyYear ? handleChange(value) : setYear(value);
  };

  const handleSelection = (value) => {
    handleChange(value);
  };

  useEffect(() => {
    if (handleChange && !onlyYear && type === "month") {
      const str = `${month}/${year}`;
      handleChange(str);
    }
  }, [year, month]);

  return (
    <div className="resumeOptimizerForm_input_container">
      {type === "selection" ? (
        <>
          <Select
            defaultValue={value}
            onChange={handleSelection}
            className="ROSelectionInput"
            dropdownRender={(menu) => (
              <>
                {menu}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Input
                    type="text"
                    value={otheresValue}
                    onChange={(e) => setotheresValue(e.target.value)}
                    style={{ marginLeft: "10px" }}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setdropVals([...dropVals, otheresValue]);
                    }}
                  >
                    Add Otheres
                  </Button>
                </div>
              </>
            )}
          >
            {dropVals.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </>
      ) : (
        <>
          {type === "month" ? (
            <div className="dateSelectionContainer">
              <div className="label">{label}: </div>
              {onlyYear ? null : (
                <Select
                  defaultValue={month}
                  onChange={handleMonth}
                  className="monthSelection"
                >
                  {months.map((item) => (
                    <Option value={item.val}>{item.val}</Option>
                  ))}
                </Select>
              )}
              <Select
                defaultValue={onlyYear ? value : year}
                onChange={handleYear}
                className="monthSelection"
                style={{ width: onlyYear ? "80%" : "40%" }}
              >
                {years.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </div>
          ) : (
            <div
              className={
                error && touched
                  ? "input_withError"
                  : "resumeOptimizerForm_input"
              }
            >
              {/* <div className="RO_label">{label}</div> */}
              <GrammarlyEditorPlugin clientId="client_DTbhiJ6wdpZapKyQ5YDMzA">
                {value ? (
                  <input
                    value={value}
                    type={type}
                    disabled
                    placeholder={label}
                  />
                ) : (
                  <Field name={name} placeholder={label} type={type} />
                )}
              </GrammarlyEditorPlugin>
            </div>
          )}
        </>
      )}
      <div className="error_field">{error && touched ? `${error}*` : ""}</div>
    </div>
  );
};

export default ResumeOptimizerInput;
