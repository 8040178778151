/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Tooltip } from "antd";
import history from "../../routes/History";
import * as DashBoardActions from "../../store/dashBoard/dashboardActions";
import avatar from "../../assets/images/DefaultImage.svg";
import Optimizer from "../../assets/images/dashboard/optimizer.svg";
import JobMatch from "../../assets/images/dashboard/jobMatch.svg";
import CompanyMatch from "../../assets/images/dashboard/companyMatch.svg";
import newOfferEvaluator from "../../assets/images/dashboard/newOfferEvaluator.svg";
import personalDashboard from "../../assets/images/dashboard/personalDashboard.svg";

import Personality from "../../assets/images/dashboard/personality.svg";
import tips from "../../assets/images/dashboard/tips.svg";
import crossMark from "../../assets/images/crossMark.svg";
import closewhite from "../../assets/images/dashboard/closewhite.svg";
import explore from "../../assets/images/dashboard/explore.svg";
import Reads from "../../assets/images/dashboard/Reads.svg";
import ComingSoon from "../../assets/images/dashboard/comingSoon.svg";
import UnnlockLayout from "../layout";
import unnlockNotification from "../../components/notification";
import "./Dashboard.scss";
import ApplyCouponContainer from "./ApplyCoupon";

const upperData = [
  {
    src: CompanyMatch,
    label: "Company Match",
    desc: "Strategic recommendations based on deep learning AI analysis of your career path and industry trends.",
  },
  {
    src: explore,
    label: "Company Explore",
    desc: "Discover companies in other industries that hire people on similar career paths. Research target companies.",
  },
  {
    src: JobMatch,
    label: "Job Match",
    desc: "Don’t waste your time. See if your resume is a match for any job opening before you apply.",
  },
  {
    src: newOfferEvaluator,
    // label: "Offer Evaluator",
    label: "Job Tracker",
    desc: "Keep track of your job matches and applications.",
  },
  {
    src: Optimizer,
    label: "Resume Optimizer",
    desc: "A comprehensive analysis of your resume with  recommendations",
  },
  {
    src: personalDashboard,
    label: "Open Jobs",
    desc: "Recommended job openings based on your skill set, career profile, industry experience, and preferences.",
  },
];

const lowerData = [
  // {
  //   src: tips,
  //   label: "Tips & FAQs",
  //   desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do",
  // },
  // {
  //   src: Reads,
  //   label: "Interesting Reads",
  //   desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do",
  // },
  // {
  //   src: Personality,
  //   label: "Personality Test",
  //   desc: "Find the best career for you though our personality test",
  // },
];

const DashboardContainer2 = () => {
  const dispatch = useDispatch();
  const resumeParseData = useSelector(
    (state) => state.dashboard.resumeParseData
  );
  const loginState = useSelector((state) => state.login);
  const dashboardState = useSelector((state) => state.dashboard);
  const basicInfo = useSelector((state) => state.profile.basicInfo);
  const employementInfo = useSelector(
    (state) => state.dashboard.employementInfo
  );
  const contactInfo = useSelector((state) => state.dashboard.contactInfo);
  const [resumeErrors, SetResumeErrors] = useState("");
  const [isOfferClick, setIsOfferClick] = useState("");
  const [closeDesclimer, setcloseDesclimer] = useState(true);
  const [showApplyCouponModal, setShowApplyCouponModal] = useState(false);

  useEffect(() => {
    if (
      loginState.userData &&
      !loginState.userData.onboarding_complete &&
      !loginState.loader
    ) {
      history.push("/onBoarding");
    } else {
      if (
        loginState.userData &&
        loginState.userData.primary_resume &&
        !dashboardState.employementInfo?.employment[0]?.designation
      ) {
        dispatch(
          DashBoardActions.resumeParserRequest(
            loginState.userData.primary_resume
          )
        );
      }
    }
    handleCompanyCardClick();
  }, [loginState]);

  useEffect(() => {
    if (
      isOfferClick === "offer" &&
      dashboardState.companyFilteres?.industry_list
    ) {
      setIsOfferClick("");
      history.push("/offerEvaluator");
    }
  }, [dashboardState]);

  // useEffect(() => {
  //   if (
  //     resumeParseData &&
  //     resumeParseData.simplified_doc.ResumeQuality &&
  //     resumeParseData.simplified_doc.ResumeQuality["sov:Assessments"][
  //       "sov:Assessment"
  //     ][0]["sov:Level"]
  //   ) {
  //     SetResumeErrors(
  //       resumeParseData.simplified_doc.ResumeQuality["sov:Assessments"][
  //         "sov:Assessment"
  //       ][0]
  //     );
  //   }
  // }, [resumeParseData]);

  const checkLocked = (data) => {
    if (data === "Resume Optimizer") {
      return false;
    } else {
      return true;
    }
  };

  const Card = ({ src, label, desc }, View) => (
    <div
      className="dashboardContainer_eachcard"
      onClick={() => {
        onClickDashboardCard(label);
      }}
    >
      {checkLocked(label) ? null : (
        <>
          {resumeErrors ? (
            <Tooltip
              placement="bottom"
              title={resumeErrors["sov:Findings"]["sov:Information"][0]}
            ></Tooltip>
          ) : null}
        </>
      )}
      <img
        className="dashboardContainer_cardImage"
        src={src}
        alt={label}
        style={{ marginRight: "10px" }}
      />
      <div className="dashboardContainer_cardLabel_Desc">
        <p
          className="dashboardContainer_cardbigtext"
          // style={{ fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}
        >
          {label}
        </p>
        <div className="dashboardContainer_cardsubtext">{desc}</div>
      </div>
    </div>
  );

  const handleCompanyCardClick = () => {
    if (loginState.userData.current_company) {
      localStorage.setItem(
        "comanyMatch_companyName",
        loginState.userData.current_company.company_to_recommend
      );
      localStorage.setItem(
        "comanyMatch_unnlock_id",
        loginState.userData.current_company?.unnlock_id ||
          loginState.userData.current_company?.company_to_recommend ||
          ""
      );
      localStorage.setItem("comanyMatch_companyUrl", "");
      localStorage.setItem("comanyMatch_companyLinkedIn", "");
    }
  };

  const onClickDashboardCard = (cardLabel) => {
    switch (cardLabel) {
      case "ATS View & Analysis":
        history.push("/analysis");
        break;
      case "Personality Test":
        history.push("/personalityTest");
        break;
      case "Job Match":
        history.push({
          pathname: `/jobmatch`,
          state: {
            fromOpenJobs: false,
          },
        });
        break;
      case "Resume Optimizer":
        history.push("/resumeVault");
        break;
      case "Tips & FAQs":
        history.push("/tipsAndFAQs");
        break;
      case "Job Tracker":
        history.push("/jobTracker");
        break;
      case "Interesting Reads":
        history.push("/interestingReads");
        break;
      case "Company Match":
        history.push("/companies");
        break;
      case "Company Explore":
        history.push("/explore");
        break;
      case "Open Jobs":
        history.push("/OpenJobs");
        break;
      default:
        history.push("/dashboard");
    }
  };

  return (
    <UnnlockLayout>
      <div
        className="dashboardContainer_loggedinUserwrap"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="dashboardContainer_loggedinUserinfo">
          <div
            style={{ marginRight: "20px" }}
            onClick={() => {
              history.push("/profile");
            }}
          >
            <Avatar
              shape="circle"
              size={60}
              style={{ cursor: "pointer" }}
              src={
                basicInfo.profileImg ? (
                  <img src={basicInfo.profileImg} alt="avatar" />
                ) : (
                  <img src={avatar} alt="avatar" />
                )
              }
              icon={
                basicInfo.profileImg ? (
                  <img src={basicInfo.profileImg} alt="avatar" />
                ) : (
                  <img src={avatar} alt="avatar" />
                )
              }
            />
          </div>
          {dashboardState.loader || loginState.loader ? null : (
            <div>
              <div
                className="dashboardContainer_bigtext"
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Welcome, {contactInfo?.firstName || basicInfo?.firstName}
              </div>
              <div className="dashboardContainer_userDesignation">
                {employementInfo.employment[0]?.designation},{" "}
                {employementInfo.employment[0]?.company}
              </div>
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="PersonalDashboardBtn"
            style={{ marginRight: "32px" }}
            onClick={() => {
              history.push("/chatBot");
            }}
          >
            <button style={{ color: "rgba(0, 0, 0, 0.85)" }}>Chat Bot</button>
          </div>
          {loginState?.userData?.tier_id === 0 ? (
            <div
              className="PersonalDashboardBtn"
              style={{ marginRight: "32px" }}
              onClick={() => {
                setShowApplyCouponModal(!showApplyCouponModal);
              }}
            >
              <button style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                Apply Coupon?
              </button>
            </div>
          ) : null}
          {!loginState.userData?.email_verified && (
            <div
              className="PersonalDashboardBtn"
              style={{ marginRight: "32px" }}
            >
              <button>Email verification pending</button>
            </div>
          )}
          <div className="PersonalDashboardBtn">
            <button onClick={() => history.push("/personalDashboard")}>
              Personal Dashboard
            </button>
          </div>
        </div>
      </div>

      <div className="dashboardContainer_dashboardcardswrap">
        {closeDesclimer && loginState?.userData?.show_disclaimer && (
          <div className="desclimer">
            <div>
              <img
                src={closewhite}
                alt="close"
                onClick={() => {
                  dispatch(
                    DashBoardActions.acceptOverLayRequest({
                      key: "show_disclaimer",
                      value: "false",
                    })
                  );
                  setcloseDesclimer(false);
                }}
              />
            </div>
            <p>
              <p>You are in!</p>
              <p>
                This is your new career and job search home. Check out each tool
                below to identify target companies, find jobs that match your
                profile, manage job applications, and fix resume issues before
                you apply. Try starting with Company Match
              </p>
              <p>
                We’re in BETA! We have big plans and want to hear from you.
                Please send feedback, bugs, and feature requests to
                feedback@unnlock.com.
              </p>
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
            background: "rgba(235, 243, 255, 0.5)",
            borderRadius: "10px",
          }}
        >
          <div className="outer">
            {/* should ask why he wrapped up the him */}
            <div className="dashboardContainer_container">
              {[...upperData, ...lowerData].map((item) => Card(item, false))}
            </div>
          </div>
        </div>
      </div>
      <ApplyCouponContainer
        visible={showApplyCouponModal}
        setShowApplyCouponModal={setShowApplyCouponModal}
      />
    </UnnlockLayout>
  );
};

export default DashboardContainer2;
