export const SET_TOOL_LOADER = "SET_TOOL_LOADER";
export const SET_FORM_DATA = "SET_FORM_DATA";

export const POST_MATCHJOB_REQUEST = "POST_MATCHJOB_REQUEST";
export const POST_MATCHJOB_SUCCESS = "POST_MATCHJOB_SUCCESS";
export const POST_MATCHJOB_FAILURE = "POST_MATCHJOB_FAILURE";

export const GET_SAL_COMP_REQUEST = "GET_SAL_COMP_REQUEST";
export const GET_SAL_COMP_SUCCESS = "GET_SAL_COMP_SUCCESS";
export const GET_SAL_COMP_FAILURE = "GET_SAL_COMP_FAILURE";

export const GET_SKILL_DESIG_REQUEST = "GET_SKILL_DESIG_REQUEST";
export const GET_SKILL_DESIG_SUCCESS = "GET_SKILL_DESIG_SUCCESS";
export const GET_SKILL_DESIG_FAILURE = "GET_SKILL_DESIG_FAILURE";

export const POSTCOMP_URLS_REQUEST = "POSTCOMP_URLS_REQUEST";
export const POSTCOMP_URLS_SUCCESS = "POSTCOMP_URLS_SUCCESS";
export const POSTCOMP_URLS_FAILURE = "POSTCOMP_URLS_FAILURE";

export const GETMATCHING_SKILLS_REQUEST = "GETMATCHING_SKILLS_REQUEST";
export const GETMATCHING_SKILLS_SUCCESS = "GETMATCHING_SKILLS_SUCCESS";
export const GETMATCHING_SKILLS_FAILURE = "GETMATCHING_SKILLS_FAILURE";

export const GET_OPEN_JOBS_REQUEST = "GET_OPEN_JOBS_REQUEST";
export const GET_OPEN_JOBS_SUCCESS = "GET_OPEN_JOBS_SUCCESS";
export const GET_OPEN_JOBS_FAILURE = "GET_OPEN_JOBS_FAILURE";

export const GET_CHATBOT_ANSWER_REQUEST = "GET_CHATBOT_ANSWER_REQUEST";
export const GET_CHATBOT_ANSWER_SUCCESS = "GET_CHATBOT_ANSWER_SUCCESS";
export const GET_CHATBOT_ANSWER_FAILURE = "GET_CHATBOT_ANSWER_FAILURE";

export const SET_SELECTED_OPENJOBS_RESUME = "SET_SELECTED_OPENJOBS_RESUME";

export const SET_OPENJOB_FILTERS = "SET_OPENJOB_FILTERS";
export const SET_OPENJOB_CITIES = "SET_OPENJOB_CITIES";

export const RESET = "RESET";
