/* eslint-disable */
import { put, takeLatest, call, select } from "redux-saga/effects";
import { message } from "antd";
import * as ACTIONS from "./jobMatchActions";
import * as TYPES from "./jobMatchTypes";
import Network from "../../network";
import unnlockNotification from "../../components/notification";
import { getSubsCountRequest } from "../newLogin/newLoginAction";
import History from "../../routes/History";
import { acceptOverLayRequest } from "../dashBoard/dashboardActions";

const { API, Config } = Network;

const getTrackerId = (list, id) => {
  return list?.filter((item) => item.job_match_id === id)[0]?.job_tracker_id;
};

function* getJobMatchResults(action) {
  try {
    const jobData = action.payload.postdata;
    const userData = yield select((state) => state.login.userData);
    const { trackerIDToUpdate, jobTrackeres } = yield select(
      (state) => state.jobmatch
    );
    const result = yield call(
      API.post,
      Config.jobMatch.jobMatchResult(jobData.resumeId),
      jobData.data,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    if (result?.job_match_id) {
      if (
        jobData?.data?.open_job_id &&
        userData?.oj_favorite &&
        !userData?.oj_favorite
          .map((item) => item.ojId)
          ?.includes(jobData?.data?.open_job_id)
      ) {
        const jobMatchIdsArr = [
          ...userData?.oj_favorite,
          {
            jMId: result?.job_match_id,
            ojId: jobData?.data?.open_job_id,
          },
        ];
        yield put(
          acceptOverLayRequest({
            key: "oj_favorite",
            value: jobMatchIdsArr,
          })
        );
      }

      const intrestLevel =
        Math.floor(
          (result.hard_skills.bars +
            result.soft_skills.bars +
            result.industry.bars +
            result.experience.bars) /
            4
        ) || 0;
      const postData = {
        company: jobData.data.company_name,
        company_url: jobData.data.company_url,
        job_title: jobData.data.job_title,
        resume_id: jobData.resumeId,
        job_match_id: result.job_match_id,
        match_score: intrestLevel,
      };
      if (action.payload?.fromResumeOptimzer) {
        yield put(
          ACTIONS.updateJobTrackRequest({
            resume_id: jobData.resumeId,
            job_tracker_id:
              trackerIDToUpdate ||
              getTrackerId(jobTrackeres, result?.job_match_id),
            file_name: action.payload?.file_name,
          })
        );
      }
      action.payload?.donotSave
        ? null
        : yield put(
            ACTIONS.saveJobMatchRequest({
              postData,
              fromResumeOptimizer: action.payload?.fromResumeOptimizer || false,
            })
          );
      yield put(ACTIONS.jobMatchResultsSuccess(result));
      if (jobData.fromOpenJobs) {
        unnlockNotification("Job Match Added..!", "success", 0, 10);
      }
    } else {
      yield put(ACTIONS.jobMatchResultsSuccess(""));
    }

    if (result.status === "SUBSCRIPTION_EXPIRED") {
      unnlockNotification(result.message, "warning", 0, 10);
      yield put(ACTIONS.jobMatchResultsrFailure(""));
    }
    if (userData?.tier_id === 0)
      yield put(
        getSubsCountRequest({ key: "job_match_count", label: "job match" })
      );
  } catch (error) {
    yield put(ACTIONS.jobMatchResultsrFailure(error));
  }
}

function* saveJobMatchResults(action) {
  try {
    const jobData = action.payload;
    const result = yield call(
      API.post,
      Config.jobMatch.saveJobMatchResult,
      jobData.postData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    yield put(ACTIONS.saveJobMatchSuccess(result));
    if (jobData?.fromResumeOptimizer) {
      History.push("/jobTracker");
    }
    // yield put(ACTIONS.extractSkillsRequest(result.Value.Text));
  } catch (error) {
    yield put(ACTIONS.saveJobMatchrFailure(error));
  }
}

function* updateJobMatchResults(action) {
  try {
    const jobData = action.payload;
    const result = yield call(
      API.put,
      Config.jobMatch.saveJobMatchResult,
      jobData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    yield put(ACTIONS.updateJobMatchSuccess(result));
    // yield put(ACTIONS.extractSkillsRequest(result.Value.Text));
  } catch (error) {
    yield put(ACTIONS.updateJobMatchrFailure(error));
  }
}

function* getJobTrackeres(action) {
  try {
    const payload = action.payload;
    const primary_resume = yield select(
      (store) => store.login.userData?.primary_resume || []
    );
    const url = payload
      ? `${Config.jobMatch.saveJobMatchResult}?limit=all`
      : Config.jobMatch.saveJobMatchResult;
    const result = yield call(API.get, url, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    if (!result.length && payload?.fromTracker) {
      const primaryResumeId = primary_resume;
      const postdata = {
        resumeId: primaryResumeId,
        data: {
          primary_resume: primaryResumeId,
          company_name: "Unnlock",
          company_url: "https://qa.unnlock.ai/",
          job_title: "Front end developer",
          education: "Masters",
          required_experience: 15,
          text: "sample job",
          industry: "Hospital & Health Care",
        },
      };
      yield put(ACTIONS.jobMatchResultsRequest({ postdata, donotSave: false }));
    }
    yield put(ACTIONS.getJobTrackeresSuccess(result));
    // yield put(ACTIONS.extractSkillsRequest(result.Value.Text));
  } catch (error) {
    yield put(ACTIONS.getJobTrackeresrFailure(error));
  }
}

function* getJobMatchFetch(action) {
  try {
    const jobData = action.payload;
    const result = yield call(
      API.get,
      Config.jobMatch.jobMatchFetch(jobData.matchId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.jobMatchFetchSuccess(result));
    const postdata = {
      resumeId: jobData.resume_id,
      data: {
        primary_resume: jobData.resume_id,
        company_name: result.company_name,
        company_url: result.company_url,
        job_title: result.job_title,
        education: result.education || "",
        required_experience: result.required_experience,
        text: result.text,
        open_job_id: result?.open_job_id || null,
        industry: result.industry,
        job_match_id: result.job_match_id,
      },
    };
    yield put(
      ACTIONS.jobMatchResultsRequest({
        postdata,
        donotSave: jobData?.donotSave,
        fromResumeOptimzer: jobData?.fromResumeOptimzer,
        file_name: jobData?.file_name,
      })
    );
  } catch (error) {
    yield put(ACTIONS.jobMatchFetchFailure(error));
  }
}

function* deleteJobTrack(action) {
  try {
    const jobData = action.payload;
    const result = yield call(
      API.post,
      Config.jobMatch.deleteJobTrack,
      jobData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getJobTrackeresRequest({ limit: "all" }));
    // yield put(ACTIONS.deleteJobTrackSuccess(result));
  } catch (error) {
    yield put(ACTIONS.deleteJobTrackrFailure(error));
  }
}

function* updateJobTrack(action) {
  try {
    const jobData = action.payload;
    const result = yield call(
      API.put,
      Config.jobMatch.updateJobTrack,
      jobData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.updateJobTrackSuccess(result));
    // yield put(ACTIONS.deleteJobTrackSuccess(result));
  } catch (error) {
    yield put(ACTIONS.updateJobTrackrFailure(error));
  }
}

export function* requestJobMatchResults() {
  yield takeLatest(TYPES.JOBMATCHRESULTS_REQUEST, getJobMatchResults);
}

export function* requestJobTrackeres() {
  yield takeLatest(TYPES.GET_JOBTACKERS_REQUEST, getJobTrackeres);
}

export function* requestJobmatchSave() {
  yield takeLatest(TYPES.SAVE_JOBMATCHRESULTS_REQUEST, saveJobMatchResults);
}

export function* requestJobmatchUpdate() {
  yield takeLatest(TYPES.UPDATE_JOBMATCHRESULTS_REQUEST, updateJobMatchResults);
}

export function* requestJobMatchFetch() {
  yield takeLatest(TYPES.JOBMATCHFETCH_REQUEST, getJobMatchFetch);
}
export function* requestJobmatchDelete() {
  yield takeLatest(TYPES.DELETE_JOBTACKERS_REQUEST, deleteJobTrack);
}
export function* requestJobTrackUpdate() {
  yield takeLatest(TYPES.UPDATE_JOBTACKERS_REQUEST, updateJobTrack);
}
