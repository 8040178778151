/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Skeleton } from "antd";
import FolderSimplePlus from "../../assets/images/resumeOptimizer/FolderSimplePlus.svg";
import Check from "../../assets/images/resumeOptimizer/Check.svg";
import Uncheck from "../../assets/images/resumeOptimizer/Uncheck.svg";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  // getOtheresSectionsRequest,
  setCurrentResumePage,
  setIsOtheresAdded,
} from "../../store/resumeOptimizer/resumeOptimizerActions";

const AddOtheresModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const [selectedID, setselectedID] = useState("");
  const dispatch = useDispatch();
  const { currentResumePage, resumeScreens, loader } = useSelector(
    (state) => state.resumeOptimizer
  );

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDone = () => {
    if (selectedID) {
      // dispatch(
      //   setIsOtheresAdded({
      //     bool: true,
      //     data: {
      //       heading: data.find((item) => item.id === selectedID).title,
      //       subHeading: "",
      //       description: "",
      //     },
      //   })
      // );
      dispatch(setCurrentResumePage(selectedID));
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  // useEffect(() => {
  //   dispatch(getOtheresSectionsRequest());
  // }, []);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="addOtheres_model"
      width={500}
    >
      <div className="add_otheres_modal">
        <div className="title">Select the sections you want to look-out</div>
        <div className="content">
          {resumeScreens
            .filter((item) => item.id !== currentResumePage)
            .map((item) => (
              <Skeleton loading={loader} active>
                <div className="item" onClick={() => setselectedID(item.id)}>
                  <img
                    src={selectedID === item.id ? Check : Uncheck}
                    className="checked"
                  />
                  <img src={FolderSimplePlus} className="folderIcon" />{" "}
                  <div className="name">{item.title}</div>
                </div>
              </Skeleton>
            ))}
        </div>
        <div className="submit_btnContainer">
          <button className="done_btn" onClick={() => handleDone()}>
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddOtheresModal;
