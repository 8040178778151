/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "antd";

import "./index.scss";
import Preview from "./preview";

const PreviewModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="preview_model"
      width={900}
    >
      <Preview isEdit={false} />
    </Modal>
  );
};

export default PreviewModal;
