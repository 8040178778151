/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Button, Radio, Skeleton, message } from "antd";
import "../Profile/model.scss";
import { useDispatch, useSelector } from "react-redux";
// import Preview from "../ResumeOpimizer/preview";
import CommonInput from "../ICDProvider/commonInput";
import unnlockNotification from "../../components/notification";
// import {
//   downloadResumePdfFailure,
//   downloadResumePdfRequest,
// } from "../../store/resumeOptimizer/resumeOptimizerActions";

const DownloadModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const [downLoader, setdownLoadaer] = useState(false);
  const [downloadLink, setdownloadLink] = useState("");
  const [name, setName] = useState("");
  // const [value, setValue] = useState(1);
  // const dispatch = useDispatch();
  // const { loader, downloadLoader, downloadLink } = useSelector(
  //   (state) => state.resumeOptimizer
  // );
  const { loader, currentResume } = useSelector(
    (state) => state.resumeOptimizer
  );
  // const onChange = (e) => {
  //   setValue(e.target.value);
  // };

  const handleDownload = () => {
    // setdownLoadaer(true);
    // var myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json, text/plain, */*");
    // myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    // myHeaders.append("Authorization", localStorage.getItem("unnlockUserToken"));
    // myHeaders.append("Connection", "keep-alive");
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   resume_id: currentResume,
    //   file_name: name,
    // });

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   `http://ec2-18-119-100-184.us-east-2.compute.amazonaws.com:8080/pdf`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     setdownLoadaer(false);
    setdownloadLink(
      `https://server.unnlock.ai/pdf/${currentResume}?file_name=${name}`
    );
    document
      .getElementById("downloadLink")
      .setAttribute(
        "href",
        `https://server.unnlock.ai/pdf/${currentResume}?file_name=${name}`
      );
    // })
    // .catch((error) => {
    //   setdownLoadaer(false);
    //   console.log("error", error);
    // });
  };

  useEffect(() => {
    if (downloadLink) {
      document.getElementById("downloadLink").click();
      // dispatch(downloadResumePdfFailure());
      setdownloadLink("");
    }
  }, [downloadLink]);

  const handleOk = () => {
    if (name) {
      handleDownload();
      // `<!DOCTYPE html> <head> <meta charset='UTF-8'></head> <body>${
      //   document.getElementById("getHtmlTemplateForDownload").outerHTML
      // }</body> </html>`
      // dispatch(
      //   downloadResumePdfRequest({
      //     data: `<!DOCTYPE html> <head> <meta charset='UTF-8'></head> <body>${
      //       document.getElementById("getHtmlTemplateForDownload").outerHTML
      //     }</body> </html>`,
      //     format: value === 1 ? "pdf" : "docx",
      //     fileName: "sample",
      //   })
      // );
      setIsModalVisible(false);
    } else {
      unnlockNotification("Please Enter File Name", "warning");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="custom_confirm_model"
    >
      <Skeleton loading={loader} active>
        <div className="model_body">
          <div className="content_holder" style={{ width: "100%" }}>
            {/* <div className="radio_buttons">
              <h3>Select the download format</h3>
              <Radio.Group
                onChange={onChange}
                value={value}
                style={{ marginBottom: "15px" }}
              >
                <Radio value={1}>PDF</Radio>
                <Radio value={2}>DOCX</Radio>
              </Radio.Group>
            </div>
            <div
              id="getHtmlTemplateForDownload"
              style={{ contentVisibility: "hidden" }}
            >
              <Preview isEdit={false} />
            </div> */}
            <CommonInput
              label=""
              name="companyName"
              placeholder="Enter file name"
              value={name}
              handleChange={(e) => setName(e.target.value)}
              style={{
                width: "66%",
                marginTop: "10px",
                marginBottom: "10px",
                border: "none",
                background: "transparent",
              }}
            />
            <a
              target="_blank"
              id="downloadLink"
              href=""
              style={{ visibility: "hidden" }}
            />

            <div className="buttons_container">
              <Button type="primary" className="custom_btn" onClick={handleOk}>
                {downLoader ? "Loading.." : "Download PDF"}
              </Button>
              <Button type="link" className="custom_btn" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Skeleton>
    </Modal>
  );
};

export default DownloadModal;
