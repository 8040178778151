/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import history from "../../routes/History";
import MiinedButton from "../../components/Button";
import "../Profile/model.scss";

const SubsriptionAlertModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className={"custom_addSkillsModal"}
    >
      <div className="model_body">
        <div className="content_holder" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "10px 10px 0px",
            }}
          >
            <div style={{}}>
              Your account needs to be upgraded to avail this feature.
            </div>
            <MiinedButton
              title={"Subsribe"}
              onClick={(e) => {
                e.stopPropagation();
                history.push("/subscriptions");
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubsriptionAlertModal;
