/* eslint-disable */
import React, { useEffect, useState } from "react";
import { List, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Heartfilled from "../../assets/images/componies/heartFilled.svg";
import Heart from "../../assets/images/componies/heart.svg";
import DefaultImage from "../../assets/images/componies/defaultImage.svg";
import Globe from "../../assets/images/componies/Globe.svg";
import LinkedinLogo from "../../assets/images/componies/LinkedinLogo.svg";
import { useSelector } from "react-redux";
import unnlockNotification from "../../components/notification";
import "./Companies.scss";

const CompanyCard = ({
  item,
  getFavClick,
  handleCompanyClicked,
  isFaverate,
  selectedCompany,
  fromDashboard,
  className,
}) => {
  const faverateLoader = useSelector((state) => state.componies.faverateLoader);
  const checkvalidArray = (data) => {
    if (Array.isArray(data) && data.length) {
      return true;
    } else return false;
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <List.Item
      className={
        fromDashboard ? "companyItemfromDashboard" : `companyItem ${className}`
      }
      key={item.unnlock_id}
    >
      <div
        className="companyItemboxShadow"
        onClick={() => handleCompanyClicked(item.unnlock_id)}
      >
        <div className="descriptionHolder">
          <img
            src={
              item.logo_url
                ? item.logo_url
                : "/static/media/defaultImage.dc9d8294cd9de7750519e6b8bc209104.svg"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = DefaultImage;
            }}
            alt="cl"
            className="companyLogo"
          />
          <div style={{ width: "100%" }}>
            <div className="companyName">{item.company}</div>
            <div className="cityName">
              {checkvalidArray(item.city_name) ? item.city_name[0].name : null}
            </div>
          </div>

          {fromDashboard ? null : (
            <>
              <img
                className="like-img"
                src={isFaverate ? Heartfilled : Heart}
                alt="like"
                onClick={(e) => {
                  e.stopPropagation();
                  getFavClick(isFaverate ? "remove" : "add", item.unnlock_id);
                }}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                }}
              />
            </>
          )}
        </div>
        <div className="companyAttributes">
          <div>
            <div style={{ fontWeight: 600 }}>Employees</div>
            <div>{item.company_size}</div>
          </div>
          <div className="industry">
            <div style={{ fontWeight: 600 }}>Industry</div>
            <div
              // style={{
              //   whiteSpace: "nowrap",
              //   maxWidth: "224px",
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              // }}
              className="industryname"
            >
              {item.industry}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: 600 }}>Quick Links</div>
            <div className="quickLinks">
              {item.company_web_url ? (
                <a
                  href={item.company_web_url}
                  target="_blank"
                  style={{ marginRight: "10px" }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <img src={Globe} alt="web" />
                </a>
              ) : null}
              {item.l_url_jobs ? (
                <a
                  href={"https://" + item.l_url_jobs}
                  target="_blank"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <img src={LinkedinLogo} alt="linked in" />
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export default CompanyCard;
