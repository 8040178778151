// Register Types
export const REGISTER_REQUEST = "register/REGISTER_REQUEST";
export const REGISTER_REQUEST_SUCCESS = "register/REGISTER_REQUEST_SUCCESS";
export const REGISTER_REQUEST_FAILURE = "register/REGISTER_REQUEST_FAILURE";

// Upload Resume Types
export const UPLOAD_RESUME_REQUEST = "register/UPLOAD_RESUME_REQUEST";
export const UPLOAD_RESUME_SUCCESS = "register/UPLOAD_RESUME_SUCCESS";
export const UPLOAD_RESUME_FAILURE = "register/UPLOAD_RESUME_FAILURE";

// Social Register Types
export const SOCIAL_REGISTER_REQUEST = "register/SOCIAL_REGISTER_REQUEST";
export const SOCIAL_REGISTER_SUCCESS = "register/SOCIAL_REGISTER_SUCCESS";
export const SOCIAL_REGISTER_FAILURE = "register/SOCIAL_REGISTER_FAILURE";

export const GET_ALL_SOCS_REQUEST = "register/GET_ALL_SOCS_REQUEST";
export const GET_ALL_SOCS_SUCCESS = "register/GET_ALL_SOCS_SUCCESS";
export const GET_ALL_SOCS_FAILURE = "register/GET_ALL_SOCS_FAILURE";

export const SAVE_USER_SOCS_REQUEST = "register/SAVE_USER_SOCS_REQUEST";
export const SAVE_USER_SOCS_SUCCESS = "register/SAVE_USER_SOCS_SUCCESS";
export const SAVE_USER_SOCS_FAILURE = "register/SAVE_USER_SOCS_FAILURE";

export const SAVE_USER_SUBS_REQUEST = "register/SAVE_USER_SUBS_REQUEST";
export const SAVE_USER_SUBS_SUCCESS = "register/SAVE_USER_SUBS_SUCCESS";
export const SAVE_USER_SUBS_FAILURE = "register/SAVE_USER_SUBS_FAILURE";

export const SAVEPREUPLOADDATA = "register/SAVEPREUPLOADDATA";

// Reset
export const RESET = "login/RESET";
