import * as types from "./toolsTypes";

const INITIAL_STATE = {
  loading: false,
  formData: {
    companyname: "",
    url: "",
    title: "",
    text: "",
  },
  matchJobResults: {
    hardSkills: [],
    softSkills: [],
  },
  salaryComponation: "",
  selectedResumeId: "",
  skillsFromDesignation: [],
  matchingSkills: "",
  openJobs: {},
  openJobCities: [],
  appliedOpenJobFilters: {
    state: [],
    city: [],
  },
  chatBotAnswers: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TOOL_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_OPENJOB_CITIES:
      return {
        ...state,
        openJobCities: action.payload,
      };
    case types.SET_OPENJOB_FILTERS:
      return {
        ...state,
        appliedOpenJobFilters: action.payload,
      };
    case types.SET_SELECTED_OPENJOBS_RESUME:
      return {
        ...state,
        selectedResumeId: action.payload,
      };
    case types.POST_MATCHJOB_REQUEST:
      return {
        ...state,
      };
    case types.POST_MATCHJOB_SUCCESS:
      return {
        ...state,
        matchJobResults: {
          ...state.matchJobResults,
          hardSkills: action.payload.hard_skills,
          softSkills: action.payload.soft_skills,
        },
      };
    case types.POST_MATCHJOB_FAILURE:
      return {
        ...state,
      };
    case types.GET_SAL_COMP_REQUEST:
      return {
        ...state,
      };
    case types.GET_SAL_COMP_SUCCESS:
      return {
        ...state,
        salaryComponation: { ...action.payload },
      };
    case types.GET_SAL_COMP_FAILURE:
      return {
        ...state,
      };
    case types.GET_SKILL_DESIG_REQUEST:
      return {
        ...state,
      };
    case types.GET_SKILL_DESIG_SUCCESS:
      return {
        ...state,
        skillsFromDesignation: action.payload,
      };
    case types.GET_SKILL_DESIG_FAILURE:
      return {
        ...state,
      };
    case types.GET_OPEN_JOBS_REQUEST:
      return {
        ...state,
      };
    case types.GET_OPEN_JOBS_SUCCESS:
      return {
        ...state,
        openJobs: action.payload,
      };
    case types.GET_OPEN_JOBS_FAILURE:
      return {
        ...state,
      };
    case types.GET_CHATBOT_ANSWER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CHATBOT_ANSWER_SUCCESS:
      return {
        ...state,
        chatBotAnswers: action.payload?.response || "",
        loading: false,
      };
    case types.GET_CHATBOT_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.SET_FORM_DATA:
      return {
        ...state,
        formData: { ...action.payload },
      };
    case types.GETMATCHING_SKILLS_SUCCESS:
      return {
        ...state,
        matchingSkills: action.payload,
      };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
