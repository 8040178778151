/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Checkbox, message } from "antd";
import * as RegisterActions from "../../store/newRegister/registerActions";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import history from "../../routes/History";
import UploadResume from "./uploadResume";
import SocialRegistration from "./socialLogin";
import titleImage from "../../assets/images/UnnlockNew.svg";
import passtick from "../../assets/images/register/passtick.svg";
import OnboardingHeader from "../../components/OnboardingHeader";
// import demoImage from "../../assets/images/register/Screenshot.png";
import "./style.scss";
import NewLogin from "../NewLogin/Login";
import UnnlockLayout from "../layout";
import CommonInput from "../ICDProvider/commonInput";
// import UploadRegisterLastScreen from "./uploadRegisterLastScreen";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid Email").required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password does not fulfill all criterias."
    ),
});

const NewRegistration = () => {
  const dispatch = useDispatch();
  const {
    loader,
    dataAfterRegister,
    userAlreadyExists,
    uploadResumeSuccess,
    dataAfterResumeParse,
    uploadResumeLoader,
    preUpladData,
    error,
  } = useSelector((state) => state.register);
  const loginState = useSelector((state) => state.login);
  const [showUploadResume, setUploadResume] = useState(false);
  const [registrationData, setRegistrationData] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState(false);
  const [checkOneNumber, setCheckOneNumber] = useState(false);
  const [checkOneSymbol, setCheckOneSymbol] = useState(false);
  const [checkOneUpperCase, setCheckOneUpperCase] = useState(false);
  const [checkLength, setCheckLength] = useState(false);
  const [isRegistrationScreen, setisRegistrationScreen] = useState(false);

  const validateNums = (e) => /\d/.test(e);
  const validateCaps = (e) => e.toLowerCase() != e;
  const validateSpecialChars = (e) =>
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e);
  const validateLength = (e) => (e.length >= 8 ? true : false);

  const handleRegister = (data) => {
    if (checkOneNumber && checkOneSymbol && checkOneUpperCase) {
      if (checked) {
        setRegistrationData(data);
        dispatch(
          RegisterActions.registerRequest({ ...data, primary_resume: "" })
        );
      } else {
        setCheckedError(true);
      }
    }
  };

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setCheckedError(false);
    } else {
      setCheckedError(true);
    }
  };

  const handleChange = (e) => {
    setCheckOneNumber(validateNums(e));
    setCheckOneSymbol(validateSpecialChars(e));
    setCheckOneUpperCase(validateCaps(e));
    setCheckLength(validateLength(e));
  };

  const parseResume = (data, filename) => {
    const postData = {
      resData: data,
      userId: loginState.userData
        ? loginState.userData.user_id
        : dataAfterRegister.user_id,
      file_name: filename,
    };
    dispatch(RegisterActions.uploadResumeRequest(postData));
  };

  useEffect(() => {
    if (dataAfterRegister && !userAlreadyExists) {
      localStorage.clear();
      if (preUpladData !== "") {
        parseResume(preUpladData.resData, preUpladData.file_name);
      } else {
        setUploadResume(true);
      }
    }
  }, [dataAfterRegister]);

  useEffect(() => {
    if (uploadResumeSuccess && dataAfterResumeParse) {
      dispatch(
        LoginActions.loginRequest({
          email: registrationData.email,
          password: registrationData.password,
        })
      );
      if (preUpladData !== "") {
        setUploadResume(true);
      }
    }
  }, [uploadResumeSuccess]);

  useEffect(() => {
    if (preUpladData !== "") {
      setisRegistrationScreen(true);
    }
  }, [preUpladData]);

  useEffect(() => {
    if (localStorage) {
      const userTokan = localStorage.getItem("unnlockUserToken");
      if (userTokan) {
        history.push("/dashboard");
      }
    }
  }, []);

  // useEffect(() => {
  //   if (error !== "" || loginState.error !== "") {
  //     console.log(error, loginState.error);
  //     // dispatch(RegisterActions.registerFailure(""));
  //     // dispatch(LoginActions.loginFailure(""));
  //     message.error("Something went wrong please try again!");
  //     localStorage.clear();
  //     setUploadResume(false);
  //   }
  // }, [error, loginState.error]);

  const preUploadLoader = preUpladData !== "" ? uploadResumeLoader : false;

  return (
    <UnnlockLayout
      dontDisplayHeader={true}
      background="white"
      optionalHeader={
        <>
          <div className="loginPage_header">
            <div className="loginPage_header_inner">
              <img src={titleImage} alt="titleImage" className="unnlockIcon" onClick={() => {
                history.push("/");
              }} />
            </div>
          </div>
          {showUploadResume ? <OnboardingHeader /> : null}
        </>
      }
    >
      <div className="register-container">
        <Spin
          size="large"
          spinning={(!showUploadResume && loader) || preUploadLoader}
          style={{
            fontSize: "24px",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            color: "purple",
            height: "100%",
          }}
        >
          {showUploadResume ? (
            <>
              <UploadResume parseResume={parseResume} />
            </>
          ) : (
            <>
              {isRegistrationScreen ? (
                <div className="registrationScreen">
                  <div className="register_form_container">
                    <div className="register_form_container_outer">
                      <div className="register_form_container_inner">
                        <div className="newUserTitle">New User</div>
                        <div>
                          <p className="socialLoginsTitle">
                            Sign up using social networks
                          </p>
                          <SocialRegistration fromLogin={true} />
                        </div>
                        <Formik
                          initialValues={{
                            first_name: "",
                            last_name: "",
                            email: "",
                            password: "",
                          }}
                          validationSchema={DisplayingErrorMessagesSchema}
                          onSubmit={(values) => {
                            // console.log(values, "export default");
                            handleRegister(values);
                          }}
                        >
                          {({ errors, touched }) => (
                            <Form>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ width: "49%" }}>
                                  <CommonInput
                                    formField
                                    name="first_name"
                                    placeholder="First Name"
                                  />
                                  <div
                                    className="error_fields"
                                    style={{
                                      color:
                                        touched.first_name && errors.first_name
                                          ? ""
                                          : "transparent",
                                    }}
                                  >
                                    {errors?.first_name}
                                  </div>
                                </div>
                                <div style={{ width: "49%" }}>
                                  <CommonInput
                                    formField
                                    name="last_name"
                                    placeholder="Last Name"
                                  />
                                  <div
                                    className="error_fields"
                                    style={{
                                      color:
                                        touched.last_name && errors.last_name
                                          ? ""
                                          : "transparent",
                                    }}
                                  >
                                    {errors?.last_name}
                                  </div>
                                </div>
                              </div>
                              <CommonInput
                                formField
                                name="email"
                                placeholder="Email"
                              />
                              <div
                                className="error_fields"
                                style={{
                                  color:
                                    touched.email && errors.email
                                      ? ""
                                      : "transparent",
                                }}
                              >
                                {errors?.email}
                              </div>
                              <CommonInput
                                formField
                                name="password"
                                type="password"
                                placeholder="Password"
                                validate={(e) => handleChange(e)}
                              />
                              {/* <div
                                className="error_fields"
                                style={{
                                  marginBottom: "0px",
                                  color:
                                    touched.password && errors.password
                                      ? ""
                                      : "transparent",
                                }}
                              >
                                {errors?.password}
                              </div> */}
                              <div
                                style={{ display: "flex", padding: "10px 0px" }}
                              >
                                <div style={{ marginRight: "78px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="circle"></span>
                                    <p
                                      className={
                                        checkLength ? "passtxtgreen" : "passtxt"
                                      }
                                    >
                                      Minimum 8 characters{" "}
                                    </p>
                                    {checkLength && (
                                      <img src={passtick} alt="" />
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="circle"></span>
                                    <p
                                      className={
                                        checkOneNumber
                                          ? "passtxtgreen"
                                          : "passtxt"
                                      }
                                    >
                                      1 Number
                                    </p>
                                    {checkOneNumber && (
                                      <img src={passtick} alt="" />
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="circle"></span>
                                    <p
                                      className={
                                        checkOneSymbol
                                          ? "passtxtgreen"
                                          : "passtxt"
                                      }
                                    >
                                      1 Symbol
                                    </p>
                                    {checkOneSymbol && (
                                      <img src={passtick} alt="" />
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="circle"></span>
                                    <p
                                      className={
                                        checkOneUpperCase
                                          ? "passtxtgreen"
                                          : "passtxt"
                                      }
                                    >
                                      1 Uppercase
                                    </p>
                                    {checkOneUpperCase && (
                                      <img src={passtick} alt="" />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  padding: "5px",
                                  paddingLeft: "0",
                                  marginTop: "0px",
                                  fontSize: "12px",
                                }}
                              >
                                <Checkbox
                                  checked={checked}
                                  onChange={onCheckboxChange}
                                />
                                <span>
                                  {" "}
                                  I agree to the <a href="www.unnlock.com/terms" target="_blank"> Terms and Conditions </a> and
                                  <a href="www.unnlock.com/privacy" target="_blank"> Privacy Policy</a>.
                                </span>
                                {checkedError ? (
                                  <div className="error_fields">
                                    Please Accept Terms and Conditions.
                                  </div>
                                ) : null}
                              </div>
                              <button
                                type="submit"
                                className="register_now_btn"
                              >
                                Register Now
                              </button>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="loginScreen">
                  <div className="left_side">
                    <NewLogin
                      setUploadResume={setUploadResume}
                      showUploadResume={showUploadResume}
                      setRegistrationData={setRegistrationData}
                      handleClick={() => setisRegistrationScreen(true)}
                    />
                  </div>
                  {/* <div className="right_side">
                    <div className="newUserTitle">New User?</div>
                    <p className="signUpTitle">Sign up</p>
                    <p className="to">to</p>
                    <p className="unnllockYurPotential">
                      unnlock your potential
                    </p>
                    <div style={{ flex: "1" }}>
                      <p className="description">
                        Lorem ipsum dolor sit amet, adipisicing elit, sed
                        doadipi sicing elit, sed do
                      </p>
                    </div>
                    <button
                      type="submit"
                      style={{}}
                      className="register_now_btn"
                      onClick={() => setisRegistrationScreen(true)}
                    >
                      Sign Up
                    </button>
                  </div> */}
                </div>
              )}
            </>
          )}
        </Spin>
        {showUploadResume ? null : (
          <>
            <div className="footer_login"></div>
          </>
        )}
      </div>
    </UnnlockLayout>
  );
};

export default NewRegistration;
