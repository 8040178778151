import axios from "axios";

const API = {
  requesttimeout: 20000,

  getDefaultHeaders: () => {
    const defaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return defaultHeaders;
  },

  get: (route, headers) => {
    const getPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, null, "get", headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(res);
      });
    });
    return getPromise;
  },

  post: (route, params, headers) => {
    const postPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, params, "post", headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return postPromise;
  },

  put: (route, params, headers) => {
    const getPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, params, "put", headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return getPromise;
  },
  // API.prepareConfig(route, params, "put", headers, callback),
  delete: (route, headers) => {
    const getPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, null, "delete", headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return getPromise;
  },

  patch: (route, params, headers) => {
    const getPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, params, "patch", headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return getPromise;
  },

  prepareConfig: async (routeurl, params, methodType, headers, callback) => {
    const config = {
      method: methodType,
      url: routeurl,
      data: params,
      headers: { ...API.getDefaultHeaders, ...headers },
      timeout: API.requesttimeout,
    };
    API.call(config, callback);
  },

  call: (config, callback) => {
    axios(config)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  },
};

export default API;
